/* src/app/features/funding-program/components/create-order/create-order.component.scss */
input[type=radio] {
  display: none;
}
.radio-button {
  display: inline-block;
  margin: 5px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #000 !important;
  font-weight: bold;
  cursor: pointer;
}
.checked {
  background-color: var(--secondary) !important;
  color: #4be8e6 !important;
}
input[type=radio] + label {
  color: #808080 !important;
}
input[type=radio]:hover + label {
  background-color: var(--secondary) !important;
  color: #4be8e6 !important;
}
input[type=radio]:checked + label {
  background-color: var(--secondary) !important;
  color: #4be8e6 !important;
}
.box-shadow {
  box-shadow: 0px 0px 18px 0.2px rgba(0, 0, 0, 0.15) !important;
  border-radius: 10px;
}
.card {
  border-bottom: 3px solid var(--secondary) !important;
}
/*# sourceMappingURL=create-order.component.css.map */
