/* src/app/shared/pages/user/user.component.scss */
.class-user {
  padding: 80px 30px 30px 80px !important;
}
.card-img-top {
  width: 100%;
  height: 200px;
  padding: 10px !important;
  border-radius: 15px !important;
}
.card {
  background-color: white !important;
}
.card-title {
  font-weight: 400 !important;
  font-size: 1.2em;
}
.form-control {
  border-color: var(--secondary);
  border-width: 1px !important;
  border-radius: 3px !important;
}
h6 {
  color: var(--secondary) !important;
}
small,
label {
  color: var(--secondary) !important;
}
.text-danger {
  color: var(--danger) !important;
}
.text-respuesta {
  color: var(--primary) !important;
  font-size: 1.2em !important;
}
.content {
  background-image: url(https://res.cloudinary.com/ddw7odxzr/image/upload/v1696042471/webapp/FONDO_zftidw.jpg) !important;
}
.card-img-top {
  width: 100%;
  height: 200px;
  padding: 10px !important;
  border-radius: 15px !important;
}
hr {
  border: var(--primary) 1.5px solid;
}
.shadow {
  box-shadow: 0px 0px 18px 0.2px rgba(0, 0, 0, 0.15) !important;
  border-radius: 10px;
}
/*# sourceMappingURL=user.component.css.map */
