<div class="content">
  <div class="shadow row mx-4">
    <ol class="breadcrumb bg-transparent ml-3">
      <li class="breadcrumb-item text-primary">
        <a routerLink="/dashboard"> Dashboard </a>
      </li>
      <li class="breadcrumb-item">Crear curso Paso a paso</li>
    </ol>
    <div class="col-md-10 mr-auto ml-auto shadow">
      <div class="wizard-container">
        <div
          class="bs-stepper card card-wizard"
          data-color="primary"
          id="wizardProfile"
        >
          <div class="card-header text-left">
            <h3 class="card-title">CREAR NUEVO CURSO</h3>

            <h2 class="description mt-40">Crear curso paso a paso</h2>
            <div class="wizard-navigation progress-primary">
              <div class="progress-with-circle progress-primary">
                <progressbar class="progress-bar" [value]="value">
                </progressbar>
              </div>
              <ul class="nav nav-pills nav-pills-danger bs-stepper-header">
                <li class="step nav-item" data-target="#course">
                  <a
                    class="step-trigger nav-link active"
                    [ngClass]="{ checked: checked }"
                    (click)="
                      value = 15; $event.preventDefault(); checked = true
                    "
                  >
                    <i
                      class="tim-icons icon-book-bookmark"
                      (click)="checked = true"
                    >
                    </i>
                    <p (click)="checked = true">Curso</p>
                  </a>
                </li>
                <li class="step nav-item" data-target="#module">
                  <a
                    class="step-trigger nav-link"
                    [ngClass]="{ checked: checked1 }"
                    (click)="
                      value = 38; $event.preventDefault(); checked1 = true
                    "
                  >
                    <i
                      class="tim-icons icon-components"
                      (click)="checked = true"
                    >
                    </i>
                    <p (click)="checked1 = true">Módulo</p>
                  </a>
                </li>
                <li class="step nav-item" data-target="#topics">
                  <a
                    class="step-trigger nav-link"
                    [ngClass]="{ checked: checked2 }"
                    (click)="
                      value = 64; $event.preventDefault(); checked2 = true
                    "
                  >
                    <i class="tim-icons icon-paper" (click)="checked = true">
                    </i>
                    <p (click)="checked2 = true">Temarios</p>
                  </a>
                </li>
                <li class="step nav-item" data-target="#quizzes">
                  <a
                    class="step-trigger nav-link"
                    [ngClass]="{ checked: checked3 }"
                    (click)="
                      value = 100; $event.preventDefault(); checked3 = true
                    "
                  >
                    <i class="tim-icons icon-trophy" (click)="checked = true">
                    </i>
                    <p (click)="checked3 = true">Exámen</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="bs-stepper-content card">
            <div class="tab-content">
              <!-- COURSE -->
              <div class="tab-pane show" id="course">
                <form
                  class="form"
                  [formGroup]="courseForm"
                  (ngSubmit)="createCourse(courseForm.value)"
                  role="form"
                >
                  <div class="card-body row shadow">
                    <div class="col-12">
                      <h4 class="card-title mt-5">Crear curso</h4>
                    </div>

                    <div class="col-md-6">
                      <label> Título </label>
                      <div class="form-group">
                        <input
                          class="form-control"
                          formControlName="title"
                          placeholder="Ingresa el título"
                          type="text"
                          required
                        />
                      </div>
                      <ng-container
                        *ngFor="let validation of validationMessages.title"
                      >
                        <small
                          class="form-text text-danger d-block"
                          *ngIf="
                            courseForm.get('title').hasError(validation.type) &&
                            (courseForm.get('title').dirty ||
                              courseForm.get('title').touched)
                          "
                        >
                          {{ validation.message }}
                        </small>
                      </ng-container>
                      <label> Descripción </label>
                      <div class="form-group">
                        <textarea
                          class="form-control"
                          formControlName="description"
                          placeholder="Descripción"
                          rows="3"
                          required
                        ></textarea>
                      </div>
                      <ng-container
                        *ngFor="
                          let validation of validationMessages.description
                        "
                      >
                        <small
                          class="form-text text-danger d-block"
                          *ngIf="
                            courseForm
                              .get('description')
                              .hasError(validation.type) &&
                            (courseForm.get('description').dirty ||
                              courseForm.get('description').touched)
                          "
                        >
                          {{ validation.message }}
                        </small>
                      </ng-container>

                      <label for="formFileSm" class="form-label"
                        >Imágen de portada</label
                      >
                      <input
                        class="form-control"
                        formControlName="path_preview_image"
                        (change)="handleImageChange($event)"
                        id="formFileSm"
                        type="file"
                        accept="image/png, image/jpeg"
                        required
                      />
                      <ng-container
                        *ngIf="firebaseStorageService.uploadPercent"
                      >
                        <div class="progress-container">
                          <span class="progress-badge"> Subiendo </span>
                          <div class="progress">
                            <progressbar
                              [value]="
                                firebaseStorageService.uploadPercent | async
                              "
                            >
                              <span class="progress-value">
                                {{
                                  firebaseStorageService.uploadPercent | async
                                }}
                              </span>
                            </progressbar>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="errorMessage">
                        <small
                          class="text-danger"
                          *ngFor="let e of errorMessage | keyvalue"
                          >{{ e.value }}</small
                        >
                        <br />
                      </ng-container>
                    </div>
                    <div class="col-md-6">
                      <div class="mt-2">
                        <h6>Instrucciones</h6>
                        <small
                          >Bienvenido, para continuar necesita llenar todos los
                          campos correspondientes a los cursos, una vez estos
                          campos sean diligenciados presiona el botón de enviar
                          y puedes seguir en la sección de
                          <strong>Módulos</strong>.</small
                        >
                      </div>
                      <div class="mt-3">
                        <h6>Restricciones</h6>
                        <small class="d-block"
                          >El campo de imágen de portada solo permite imágenes
                          en formato <strong>JPG y PNG</strong>.</small
                        >
                        <small class="d-block"
                          >El precio en decimal se delimitan por
                          <strong>punto (.)</strong> además de
                          <strong>dos</strong> decimales.</small
                        >
                      </div>
                    </div>
                    <div class="col-sm-12 mb-5">
                      <button
                        class="btn btn-fill btn-secodnary"
                        [disabled]="courseForm.invalid"
                        type="submit"
                      >
                        Guardar
                      </button>
                      <button
                        class="btn btn-fill btn-danger ml-2"
                        (click)="reset(1)"
                        type="button"
                      >
                        Borrar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <!-- MODULE -->
              <div class="tab-pane show" id="module">
                <form
                  [formGroup]="moduleForm"
                  (ngSubmit)="createModule(moduleForm.value)"
                  role="form"
                >
                  <div class="row p-3 card-body shadow">
                    <div class="col-12">
                      <h3 class="card-subtitle mt-5">
                        CREAR MÓDULOS DEL CURSO
                      </h3>
                    </div>
                    <div class="col-sm-6">
                      <label> Título </label>
                      <div class="form-group">
                        <input
                          class="form-control"
                          formControlName="name"
                          placeholder="Título del módulo"
                          type="text"
                          required
                        />
                      </div>
                      <ng-container
                        *ngFor="let validation of validationMessages.title"
                      >
                        <small
                          class="text-danger d-block"
                          *ngIf="
                            moduleForm.get('name').hasError(validation.type) &&
                            (moduleForm.get('name').dirty ||
                              moduleForm.get('name').touched)
                          "
                        >
                          {{ validation.message }}
                        </small>
                      </ng-container>

                      <ng-container *ngIf="errorMessage">
                        <small
                          class="text-danger"
                          *ngFor="let e of errorMessage | keyvalue"
                          >{{ e.value }}</small
                        >
                        <br />
                      </ng-container>

                      <div class="mt-2">
                        <label> Modulos creados</label>
                        <select
                          class="form-select form-group d-block"
                          formControlName="course"
                          size="4"
                        >
                          <option
                            *ngFor="let c of modules"
                            [value]="c?.id"
                            disabled
                          >
                            {{ c?.name | titlecase }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="mt-2">
                        <h6>Instrucciones</h6>
                        <small
                          >Ya has creado un curso, es hora de que fijemos sus
                          respectivos módulos, así que dale el título que
                          asignaremos al módulos.</small
                        >
                        <small
                          >Una vez haya creado todos los modulos haga click en
                          el botón de <strong>siguiente</strong>.</small
                        >
                      </div>
                      <div class="mt-3">
                        <h6>Restricciones</h6>
                        <small class="d-block"
                          >El título es <strong>obligatorio</strong>.</small
                        >
                      </div>
                    </div>

                    <div class="col-sm-12 mb-5">
                      <button
                        class="btn btn-fill btn-secodnary"
                        [disabled]="moduleForm.invalid"
                        type="submit"
                      >
                        Enviar
                      </button>
                      <button
                        class="btn btn-fill btn-danger ml-2"
                        (click)="reset(2)"
                        type="button"
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <!-- TOPICS -->
              <div class="show tab-pane" id="topics">
                <form
                  [formGroup]="topicForm"
                  (ngSubmit)="createTopic(topicForm.value)"
                  role="form"
                >
                  <div class="card-body row shadow">
                    <div class="col-12">
                      <h3 class="card-title mt-5">Crear Temario</h3>
                    </div>
                    <div class="col-md-6">
                      <label> Título </label>
                      <div class="form-group">
                        <input
                          class="form-control"
                          formControlName="title"
                          placeholder="Título"
                          type="text"
                          required
                        />
                      </div>
                      <ng-container
                        *ngFor="let validation of validationMessages.title"
                      >
                        <small
                          class="text-danger d-block"
                          *ngIf="
                            topicForm.get('title').hasError(validation.type) &&
                            (topicForm.get('title').dirty ||
                              topicForm.get('title').touched)
                          "
                        >
                          {{ validation.message }}
                        </small>
                      </ng-container>
                      <label> Descripción </label>
                      <div class="form-group">
                        <textarea
                          class="form-control"
                          formControlName="description"
                          placeholder="Descripción"
                          rows="3"
                          required
                        ></textarea>
                      </div>
                      <ng-container
                        *ngFor="
                          let validation of validationMessages.description
                        "
                      >
                        <small
                          class="text-danger d-block"
                          *ngIf="
                            topicForm
                              .get('description')
                              .hasError(validation.type) &&
                            (topicForm.get('description').dirty ||
                              topicForm.get('description').touched)
                          "
                        >
                          {{ validation.message }}
                        </small>
                      </ng-container>

                      <label for="formFileSm" class="form-label">Vídeo</label>
                      <input
                        class="form-control"
                        formControlName="video"
                        id="formFileSm"
                        type="file"
                        accept="video/mp4, video/ogg"
                        (change)="handleVideoChange($event)"
                        required
                      />
                      <ng-container
                        *ngIf="firebaseStorageService.uploadPercent"
                      >
                        <div class="progress-container">
                          <span class="progress-badge"> Subiendo </span>
                          <div class="progress">
                            <progressbar
                              [value]="
                                firebaseStorageService.uploadPercent | async
                              "
                            >
                              <span class="progress-value">
                                {{
                                  firebaseStorageService.uploadPercent | async
                                }}</span
                              >
                            </progressbar>
                          </div>
                        </div>
                      </ng-container>

                      <label class="form-label"> Módulo </label>
                      <select
                        class="form-select form-group d-block"
                        formControlName="module"
                        size="8"
                      >
                        <option *ngFor="let m of modules" [value]="m?.id">
                          {{ m?.name | titlecase }}
                        </option>
                      </select>

                      <label for="formFileSm" class="form-label mt-2"
                        >Archivos</label
                      >
                      <input
                        class="form-control"
                        formControlName="files"
                        id="formFileSm"
                        multiple
                        (change)="handleFilesChange($event)"
                        type="file"
                      />
                      <ng-container
                        *ngIf="firebaseStorageService.uploadPercentFiles"
                      >
                        <div class="progress-container">
                          <span class="progress-badge"> Subiendo </span>
                          <div class="progress">
                            <progressbar
                              [value]="
                                firebaseStorageService.uploadPercentFiles
                                  | async
                              "
                            >
                              <span class="progress-value">
                                {{
                                  firebaseStorageService.uploadPercentFiles
                                    | async
                                }}</span
                              >
                            </progressbar>
                          </div>
                        </div>
                      </ng-container>

                      <div class="form-group" formArrayName="links">
                        <label for="items">Links</label>
                        <div
                          *ngFor="
                            let item of topicForm.controls.links['controls'];
                            let i = index
                          "
                          [formGroupName]="i"
                          id="items"
                        >
                          <div class="row form-group">
                            <div class="col-6">
                              <input
                                class="form-control"
                                placeholder="Título"
                                type="text"
                                formControlName="title"
                              />
                            </div>
                            <div class="col-5">
                              <input
                                class="form-control"
                                placeholder="link"
                                type="link"
                                formControlName="link"
                              />
                            </div>

                            <div class="col-1">
                              <button
                                (click)="removeItem(i)"
                                class="btn btn-icon btn-danger"
                                type="button"
                              >
                                <span class="btn-inner--icon">
                                  <i class="tim-icons icon-simple-delete"> </i>
                                </span>
                              </button>
                            </div>
                          </div>
                          <ng-container
                            *ngFor="
                              let validation of validationMessages.title_link
                            "
                          >
                            <small
                              class="text-danger"
                              *ngIf="
                                this.topicForm.controls.links['controls'][0]
                                  .get('title')
                                  .hasError(validation.type) &&
                                (this.topicForm.controls.links[
                                  'controls'
                                ][0].get('title').dirty ||
                                  this.topicForm.controls.links[
                                    'controls'
                                  ][0].get('title').touched)
                              "
                            >
                              {{ "- " + validation.message }}
                            </small>
                          </ng-container>
                          <ng-container
                            *ngFor="let validation of validationMessages.link"
                          >
                            <small
                              class="text-danger"
                              *ngIf="
                                this.topicForm.controls.links['controls'][0]
                                  .get('link')
                                  .hasError(validation.type) &&
                                (this.topicForm.controls.links[
                                  'controls'
                                ][0].get('link').dirty ||
                                  this.topicForm.controls.links[
                                    'controls'
                                  ][0].get('link').touched)
                              "
                            >
                              {{ "  - " + validation.message }}
                            </small>
                          </ng-container>
                        </div>
                        <button
                          (click)="addNewLink()"
                          class="btn btn-icon btn-default"
                          type="button"
                        >
                          <span class="btn-inner--icon">
                            <i class="tim-icons icon-simple-add"> </i>
                          </span>
                        </button>
                      </div>

                      <ng-container *ngIf="errorMessage">
                        <small
                          class="text-danger"
                          *ngFor="let e of errorMessage | keyvalue"
                          >{{ e.value }}</small
                        >
                        <br />
                      </ng-container>
                    </div>

                    <div class="col-md-6">
                      <div class="mt-2">
                        <h6>Instrucciones</h6>
                        <small
                          >Hasta aquí has creado un curso, sus módulos, y ahora
                          agregaremos cada uno de los temas a su respectivo
                          módulo.</small
                        >
                      </div>
                      <div class="mt-3">
                        <h6>Restricciones</h6>
                        <small class="d-block"
                          >Es obligatorio agregar un vídeo en formato
                          <strong>mp4</strong> o <strong>ogg</strong>.</small
                        >
                      </div>
                    </div>

                    <div class="col-sm-12 mb-5 mb-5">
                      <button
                        class="btn btn-fill btn-secodnary"
                        [disabled]="topicForm.invalid"
                        type="submit"
                      >
                        Enviar
                      </button>
                      <button
                        class="btn btn-fill btn-danger ml-2"
                        (click)="reset(3)"
                        type="button"
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <!-- QUIZZES -->
              <div class="show tab-pane" id="quizzes">
                <form
                  [formGroup]="quizForm"
                  (ngSubmit)="createQuiz(quizForm.value)"
                  role="form"
                >
                  <div class="card-body row shadow">
                    <div class="col-12">
                      <h3 class="card-title mt-5">Crear exámen</h3>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group" formArrayName="questions">
                        <label for="questions">Preguntas</label>
                        <div
                          *ngFor="
                            let item of quizForm.controls.questions['controls'];
                            let i = index
                          "
                          [formGroupName]="i"
                          id="questions"
                        >
                          <fieldset class="form-group">
                            <label> Pregunta </label>
                            <div class="form-group">
                              <textarea
                                class="form-control"
                                formControlName="question"
                                placeholder="Ingrese las pregunta"
                                rows="2"
                                required
                              ></textarea>
                            </div>

                            <ng-container
                              *ngFor="
                                let validation of validationMessages.question
                              "
                            >
                              <small
                                class="text-danger"
                                *ngIf="
                                  this.quizForm.controls.questions[
                                    'controls'
                                  ][0]
                                    .get('question')
                                    .hasError(validation.type) &&
                                  (this.quizForm.controls.questions[
                                    'controls'
                                  ][0].get('question').dirty ||
                                    this.quizForm.controls.questions[
                                      'controls'
                                    ][0].get('question').touched)
                                "
                              >
                                {{ validation.message }}
                              </small>
                            </ng-container>

                            <h4 class="card-subtitle">Respuestas</h4>

                            <label>1. Opción </label>
                            <div class="form-group">
                              <textarea
                                class="form-control"
                                formControlName="optionOne"
                                placeholder="Ingrese la primera opción de respuesta"
                                rows="2"
                                required
                              ></textarea>
                            </div>
                            <ng-container
                              *ngFor="
                                let validation of validationMessages.optionOne
                              "
                            >
                              <small
                                class="d-block text-danger"
                                *ngIf="
                                  this.quizForm.controls.questions[
                                    'controls'
                                  ][0]
                                    .get('optionOne')
                                    .hasError(validation.type) &&
                                  (this.quizForm.controls.questions[
                                    'controls'
                                  ][0].get('optionOne').dirty ||
                                    this.quizForm.controls.questions[
                                      'controls'
                                    ][0].get('optionOne').touched)
                                "
                              >
                                {{ validation.message }}
                              </small>
                            </ng-container>

                            <label>2. Opción </label>
                            <div class="form-group">
                              <textarea
                                class="form-control"
                                formControlName="optionTwo"
                                placeholder="Ingrese la segunda opción de respuesta"
                                rows="2"
                              ></textarea>
                            </div>

                            <ng-container
                              *ngFor="
                                let validation of validationMessages.optionTwo
                              "
                            >
                              <small
                                class="d-block text-danger"
                                *ngIf="
                                  this.quizForm.controls.questions[
                                    'controls'
                                  ][0]
                                    .get('optionTwo')
                                    .hasError(validation.type) &&
                                  (this.quizForm.controls.questions[
                                    'controls'
                                  ][0].get('optionTwo').dirty ||
                                    this.quizForm.controls.questions[
                                      'controls'
                                    ][0].get('optionTwo').touched)
                                "
                              >
                                {{ validation.message }}
                              </small>
                            </ng-container>

                            <label>3. Opción </label>
                            <div class="form-group">
                              <textarea
                                class="form-control"
                                formControlName="optionThree"
                                placeholder="Ingrese la tercera opción de respuesta"
                                rows="2"
                              ></textarea>
                            </div>

                            <label>4. Opción</label>
                            <div class="form-group">
                              <textarea
                                class="form-control"
                                formControlName="optionFour"
                                placeholder="Ingrese la cuarta opción de respuesta"
                                rows="2"
                              ></textarea>
                            </div>

                            <label> Respuesta correcta </label>
                            <select
                              class="form-select form-group d-block"
                              formControlName="answer"
                              size="4"
                            >
                              <option value="optionOne">Opción 1</option>
                              <option value="optionTwo">Opción 2</option>
                              <option value="optionThree">Opción 3</option>
                              <option value="optionFour">Opción 4</option>
                            </select>

                            <button
                              (click)="removeQ(i)"
                              class="btn btn-simple btn-danger"
                              type="button"
                            >
                              Eliminar pregunta
                            </button>
                          </fieldset>
                        </div>
                        <button
                          (click)="addNewQ()"
                          class="btn btn-fill btn-primary"
                          type="button"
                        >
                          Agregar pregunta
                        </button>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mt-3">
                        <h6>Instrucciones</h6>
                        <small
                          >Hemos llegado al último paso, agrega cuantas
                          preguntas consideres necesarias al exámen, no olvides
                          elegir cuál de las 4 opciones es la correcta.
                        </small>
                      </div>
                      <div class="mt-3">
                        <h6>Restricciones</h6>
                        <small class="d-block"
                          >Las opciones uno y dos son obligatorias.</small
                        >
                        <small class="d-block"
                          >Es obligatorio elegir el <strong>curso</strong> al
                          que asignaremos la pregunta.</small
                        >
                      </div>
                    </div>

                    <div class="col-sm-12 mb-5 mb-5">
                      <button
                        class="btn btn-fill btn-secodnary"
                        [disabled]="quizForm.invalid"
                        type="submit"
                      >
                        Enviar
                      </button>
                      <button
                        class="btn btn-fill btn-danger ml-2"
                        (click)="reset(4)"
                        type="button"
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="card-header">
            <div class="pull-right">
              <input
                class="btn btn btn-secondary btn-lg"
                *ngIf="value < 100"
                name="next"
                type="submit"
                value="SIGUIENTE PASO"
                (click)="next()"
              />

              <!-- btn btn-simple btn-twitter -->

              <input
                class="btn btn-finish btn-fill btn-primary btn-wd"
                name="finish"
                type="button"
                value="FINALIZAR"
              />
            </div>
            <div class="pull-left" *ngIf="value > 15">
              <input
                class="btn btn btn-secondary btn-lg"
                name="previous"
                type="button"
                (click)="previous()"
                value="ANTERIOR"
              />
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
