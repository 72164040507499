<div class="content">
  <div class="row">
    <div class="col-lg-4 col-sm-12">
      <ol class="breadcrumb bg-transparent ml-3">
        <li class="breadcrumb-item">Facturación</li>
      </ol>
    </div>
  </div>
  <div class="row ml-3">
    <h2> Facturación <span class="badge badge-primary">proximamente</span></h2>
  </div>
</div>
