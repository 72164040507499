/* src/app/features/self-management/components/order-detail/order-detail.component.scss */
.shadow {
  box-shadow: 0px 0px 18px 0.2px rgba(0, 0, 0, 0.15) !important;
  border-radius: 10px;
}
.card-title {
  color: var(--secondary) !important;
  font-weight: 600 !important;
  border-bottom: 2px solid #4be8e6;
}
.card {
  border-bottom: 3px solid var(--secondary);
}
.card-title {
  color: var(--secondary) !important;
  font-weight: 600 !important;
}
.bg-label {
  border-radius: 5px;
  background-color: rgba(204, 204, 204, 0.8);
}
/*# sourceMappingURL=order-detail.component.css.map */
