/* src/app/features/teacher/components/edit-quiz/edit-quiz.component.scss */
fieldset {
  border: 0.5px solid rgb(3, 1, 92) !important;
  padding: 15px !important;
}
small,
label {
  color: var(--default) !important;
}
.mt-2 {
  margin-top: 20px !important;
}
.card-title {
  font-size: 3em !important;
  font-weight: 600 !important;
  color: var(--secondary) !important;
}
.card-subtitle {
  font-size: 1.7em !important;
  font-weight: 600 !important;
  color: var(--secondary) !important;
}
.form-control {
  border-color: var(--secondary);
  border-width: 1px !important;
  border-radius: 3px !important;
}
h6 {
  color: var(--secondary) !important;
}
.text-danger {
  color: var(--danger) !important;
}
.text-respuesta {
  color: var(--primary) !important;
  font-size: 1.2em !important;
}
/*# sourceMappingURL=edit-quiz.component.css.map */
