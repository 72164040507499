/* src/app/features/teacher/pages/create-course-by-steps/create-course-by-steps.component.scss */
.content {
  background-image: url(https://res.cloudinary.com/ddw7odxzr/image/upload/v1696042471/webapp/FONDO_zftidw.jpg) !important;
}
.shadow {
  border-radius: 10px;
}
.card-title {
  font-size: 3em !important;
  font-weight: 600 !important;
  color: var(--secondary) !important;
}
.mt-40 {
  margin-top: 50px !important;
  font-weight: 500 !important;
  color: white !important;
}
.card-subtitle {
  font-size: 1.5em !important;
  font-weight: 600 !important;
  color: var(--secondary) !important;
}
.card {
  background-color: rgba(0, 0, 0, 0) !important;
}
.card-body {
  background-color: #ffffff !important;
}
.card-footer {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.white-content {
  background-color: rgba(0, 0, 0, 0) !important;
}
.active .bs-stepper-circle {
  background: var(--primary) !important;
  color: aliceblue !important;
}
.bs-stepper-label {
  color: aliceblue !important;
}
.bs-stepper-label:hover {
  color: aliceblue;
  background: var(--primary) !important;
}
.form-control {
  border-color: var(--secondary) !important;
  border-width: 1px !important;
  border-radius: 3px !important;
}
.description {
  color: var(--secondary) !important;
}
h6 {
  color: var(--secondary) !important;
}
small,
label {
  color: var(--default) !important;
}
.text-danger {
  color: var(--danger) !important;
}
.text-respuesta {
  color: var(--primary) !important;
  font-size: 1.2em !important;
}
select {
  appearance: none;
  background-color: transparent;
  border: none;
  color: var(--primary);
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
}
/*# sourceMappingURL=create-course-by-steps.component.css.map */
