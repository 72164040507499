<div class="content">
  <div class="shadow row mx-5">
    <ol class="breadcrumb bg-transparent ml-3">
      <li class="breadcrumb-item text-primary">
        <a routerLink="/dashboard"> Dashboard </a>
      </li>
      <li class="breadcrumb-item">Administración de Cursos</li>
    </ol>
    <div class="col-md-12">
      <div class="card shadow card-subcategories card-plain">
        <div class="card-header">
          <h4 class="title-lg mt-5 ml-3">ADMINISTRAR CURSOS</h4>
          <br />
        </div>
        <div class="card-body ml-3">
          <tabset
            class="nav-pills-primary nav-pills-icons justify-content-left"
            type="pills"
          >
            <!-- COURSES -->
            <tab>
              <ng-template tabHeading>
                <i class="tim-icons icon-book-bookmark"> </i> Cursos
              </ng-template>
              <ng-container
                *ngIf="!showFormCreateCourse && !showFormEditCourse"
              >
                <div class="col-md-12">
                  <div class="card shadow">
                    <div class="card-header">
                      <div class="tools float-right">
                        <button
                          class="btn btn-primary mr-1 d-inline btn-lg"
                          (click)="createCourse()"
                          type="button"
                        >
                          CREAR NUEVO CURSO
                        </button>
                      </div>
                      <h4 class="card-title">Mis cursos</h4>
                    </div>
                    <div class="card-body">
                      <div class="table-responsive">
                        <table class="table">
                          <thead class="text-primary">
                            <tr>
                              <th class="text-center">id</th>
                              <th>Nombre</th>
                              <th class="text-center">Fecha de creación</th>
                              <th class="text-right">Acciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let c of courses;
                                index as i;
                                trackBy: trackCourse
                              "
                            >
                              <td class="text-center">{{ c?.id }}</td>
                              <td>{{ c?.title | titlecase }}</td>
                              <td class="text-center">
                                {{ c?.created_at | date }}
                              </td>
                              <td class="text-right">
                                <button
                                  class="btn btn-green btn-link btn-sm btn-icon"
                                  (click)="editCourse(c)"
                                  tooltip="Editar"
                                  type="button"
                                >
                                  <i class="tim-icons icon-pencil"> </i>
                                </button>
                                <button
                                  class="btn btn-danger btn-link btn-sm"
                                  (click)="deleteCourse(c?.id)"
                                  tooltip="Eliminar"
                                  type="button"
                                >
                                  <i class="tim-icons icon-simple-remove"> </i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="showFormCreateCourse">
                <div class="col-md-12">
                  <app-create-course
                    (showEvent)="changeStateShow($event)"
                  ></app-create-course>
                </div>
              </ng-container>
              <ng-container *ngIf="showFormEditCourse">
                <div class="col-md-12">
                  <app-edit-course
                    (showEvent)="changeStateShow($event)"
                    [course]="courseEdit"
                  >
                  </app-edit-course>
                </div>
              </ng-container>
            </tab>
            <!-- MODULES -->
            <tab>
              <ng-template tabHeading>
                <i class="tim-icons icon-components"> </i> Módulos
              </ng-template>
              <ng-container
                *ngIf="!showFormCreateModule && !showFormEditModule"
              >
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header">
                      <div class="tools float-right">
                        <button
                          class="btn btn-primary mr-1 d-inline btn-lg"
                          (click)="createModule()"
                          type="button"
                        >
                          CREAR MÓDULO
                        </button>
                        <div class="dropdown d-inline" dropdown>
                          <button
                            class="btn btn-default dropdown-toggle btn-link btn-icon dropdown-toggle"
                            data-toggle="dropdown"
                            dropdownToggle
                            type="button"
                          >
                            <i class="tim-icons icon-settings-gear-63"> </i>
                          </button>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            *dropdownMenu
                          >
                            <a
                              class="dropdown-item"
                              *ngFor="let c of courses"
                              (click)="getModulesByCourseId(c.id)"
                            >
                              {{ c.title }}
                            </a>
                          </div>
                        </div>
                      </div>
                      <h4 class="card-title">Mis módulos</h4>
                    </div>
                    <div class="card-body">
                      <div class="table-responsive">
                        <table class="table">
                          <thead class="text-primary">
                            <tr>
                              <th class="text-center">id</th>
                              <th>Nombre</th>
                              <th class="text-center">ID CURSO</th>
                              <th class="text-center">Nombre CURSO</th>
                              <th class="text-center">Fecha de creación</th>
                              <th class="text-right">Acciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let m of modules;
                                index as i;
                                trackBy: trackModule
                              "
                            >
                              <td class="text-center">
                                {{ m?.id }}
                              </td>
                              <td>{{ m?.name | titlecase }}</td>
                              <td class="text-center">
                                {{ m?.course }}
                              </td>
                              <td class="text-center">
                                {{ m?.course_name | titlecase }}
                              </td>
                              <td class="text-center">
                                {{ m?.created_at | date }}
                              </td>
                              <td class="text-right">
                                <button
                                  class="btn btn-green btn-link btn-sm btn-icon"
                                  (click)="editModule(m)"
                                  tooltip="Editar"
                                  type="button"
                                >
                                  <i class="tim-icons icon-pencil"> </i>
                                </button>
                                <button
                                  class="btn btn-danger btn-link btn-sm"
                                  (click)="deleteModule(m?.id)"
                                  tooltip="Eliminar"
                                  type="button"
                                >
                                  <i class="tim-icons icon-simple-remove"> </i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="showFormCreateModule">
                <div class="col-md-12">
                  <app-create-module
                    (showEvent)="changeStateShow($event)"
                  ></app-create-module>
                </div>
              </ng-container>
              <ng-container *ngIf="showFormEditModule">
                <div class="col-md-12">
                  <app-edit-module
                    (showEvent)="changeStateShow($event)"
                    [module]="moduleEdit"
                  >
                  </app-edit-module>
                </div>
              </ng-container>
            </tab>
            <!-- TOPICS -->
            <tab>
              <ng-template tabHeading>
                <i class="tim-icons icon-paper"> </i> Temarios
              </ng-template>
              <ng-container *ngIf="!showFormCreateTopic && !showFormEditTopic">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header">
                      <div class="tools float-right">
                        <button
                          class="btn btn-primary mr-1 d-inline btn-lg"
                          (click)="createTopic()"
                          type="button"
                        >
                          CREAR TEMARIO
                        </button>
                        <div class="dropdown d-inline" dropdown>
                          <button
                            class="btn btn-default dropdown-toggle btn-link btn-icon dropdown-toggle"
                            data-toggle="dropdown"
                            dropdownToggle
                            type="button"
                          >
                            <i class="tim-icons icon-settings-gear-63"> </i>
                          </button>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            *dropdownMenu
                          >
                            <a
                              class="dropdown-item"
                              *ngFor="let c of modules"
                              (click)="getTopicByModuleId(c.id)"
                            >
                              {{ c.name + " | " + c.course_name | titlecase }}
                            </a>
                          </div>
                        </div>
                      </div>
                      <h4 class="card-title">Mis temarios</h4>
                    </div>
                    <div class="card-body">
                      <div class="table-responsive">
                        <table class="table">
                          <thead class="text-primary">
                            <tr>
                              <th class="text-center">id</th>
                              <th>Título</th>
                              <th class="text-center">ID Módulo</th>
                              <th class="text-center">nombre Módulo</th>
                              <th class="text-center">Fecha de creación</th>
                              <th class="text-right">Acciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let t of topics;
                                index as i;
                                trackBy: trackTopics
                              "
                            >
                              <td class="text-center">{{ t?.id }}</td>
                              <td>{{ t?.title | titlecase }}</td>
                              <td class="text-center">{{ t?.module_id }}</td>
                              <td class="text-center">
                                {{ t?.module_name | titlecase }}
                              </td>
                              <td class="text-center">
                                {{ t?.created_at | date }}
                              </td>
                              <td class="text-right">
                                <button
                                  class="btn btn-green btn-link btn-sm btn-icon"
                                  (click)="editTopic(t)"
                                  tooltip="Editar"
                                  type="button"
                                >
                                  <i class="tim-icons icon-pencil"> </i>
                                </button>
                                <button
                                  class="btn btn-danger btn-link btn-sm"
                                  (click)="deleteTopic(t?.id)"
                                  tooltip="Eliminar"
                                  type="button"
                                >
                                  <i class="tim-icons icon-simple-remove"> </i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="showFormCreateTopic">
                <div class="col-md-12">
                  <app-create-topics
                    (showEvent)="changeStateShow($event)"
                  ></app-create-topics>
                </div>
              </ng-container>
              <ng-container *ngIf="showFormEditTopic">
                <div class="col-md-12">
                  <app-edit-topics
                    (showEvent)="changeStateShow($event)"
                    [topic]="topicEdit"
                  >
                  </app-edit-topics>
                </div>
              </ng-container>
            </tab>
            <!-- QUIZZES -->
            <tab>
              <ng-template tabHeading>
                <i class="tim-icons icon-trophy"> </i> Exámenes
              </ng-template>
              <ng-container
                *ngIf="!showFormCreateQuizzes && !showFormEditQuizzes"
              >
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header">
                      <div class="tools float-right">
                        <button
                          class="btn btn-primary mr-1 d-inline btn-lg"
                          (click)="createQuiz()"
                          type="button"
                        >
                          CREAR EXÁMEN
                        </button>
                        <div class="dropdown d-inline" dropdown>
                          <button
                            class="btn btn-default dropdown-toggle btn-link btn-icon dropdown-toggle"
                            data-toggle="dropdown"
                            dropdownToggle
                            type="button"
                          >
                            <i class="tim-icons icon-settings-gear-63"> </i>
                          </button>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            *dropdownMenu
                          >
                            <a
                              class="dropdown-item"
                              *ngFor="let c of courses"
                              (click)="getQuizzesByCourseId(c.id)"
                            >
                              {{ c.title | titlecase }}
                            </a>
                          </div>
                        </div>
                      </div>
                      <h4 class="card-title">Mis exámenes</h4>
                    </div>
                    <div class="card-body">
                      <div class="table-responsive">
                        <table class="table">
                          <thead class="text-primary">
                            <tr>
                              <th class="text-center">id</th>
                              <th>Pregunta</th>
                              <th class="text-center">id curso</th>
                              <th class="text-center">nombre curso</th>
                              <th class="text-center">Fecha de creación</th>
                              <th class="text-right">Acciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let q of quizzes;
                                index as i;
                                trackBy: trackQuiz
                              "
                            >
                              <td class="text-center">{{ q?.id }}</td>
                              <td>
                                {{ q?.question | titlecase | slice : 0 : 40 }}
                              </td>
                              <td class="text-center">{{ q?.course_id }}</td>
                              <td class="text-center">
                                {{ q?.course_name | titlecase }}
                              </td>
                              <td class="text-center">
                                {{ q?.created_at | date }}
                              </td>
                              <td class="text-right">
                                <button
                                  class="btn btn-green btn-link btn-sm btn-icon"
                                  (click)="editQuiz(q)"
                                  tooltip="Editar"
                                  type="button"
                                >
                                  <i class="tim-icons icon-pencil"> </i>
                                </button>
                                <button
                                  class="btn btn-danger btn-link btn-sm"
                                  (click)="deleteQuiz(q?.id)"
                                  tooltip="Eliminar"
                                  type="button"
                                >
                                  <i class="tim-icons icon-simple-remove"> </i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="showFormCreateQuizzes">
                <div class="col-md-12">
                  <app-create-quiz
                    (showEvent)="changeStateShow($event)"
                  ></app-create-quiz>
                </div>
              </ng-container>
              <ng-container *ngIf="showFormEditQuizzes">
                <div class="col-md-12">
                  <app-edit-quiz
                    (showEvent)="changeStateShow($event)"
                    [quiz]="quizEdit"
                  >
                  </app-edit-quiz>
                </div>
              </ng-container>
            </tab>
          </tabset>
        </div>
      </div>
    </div>
  </div>
</div>
