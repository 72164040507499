<div class=" card text-left">
    <form [formGroup]="quizForm" (ngSubmit)="createQuiz(quizForm.value)" role="form">

        <div class="card-body row">
            <div class="col-12">
                <h4 class=" card-title mt-5">Crear exámen</h4>
            </div>
            <div class="  col-md-6">

                <div class="form-group" formArrayName="questions">
                    <label for="questions">Preguntas</label>
                    <div *ngFor="let item of quizForm.controls.questions['controls']; let i = index;"
                        [formGroupName]="i" id="questions">



                        <fieldset class="form-group">

                            <label> Pregunta </label>
                            <div class=" form-group">
                                <textarea class="form-control" formControlName="question"
                                    placeholder="Ingrese las pregunta" rows="2" required></textarea>
                            </div>

                            <ng-container *ngFor="let validation of validationMessages.question">
                                <small class="text-danger"
                                    *ngIf="this.quizForm.controls.questions['controls'][0].get('question').hasError(validation.type) &&
                    (this.quizForm.controls.questions['controls'][0].get('question').dirty || this.quizForm.controls.questions['controls'][0].get('question').touched) ">
                                    {{validation.message}}
                                </small>
                            </ng-container>

                            <h4 class="card-subtitle">Respuestas</h4>

                            <label>1. Opción </label>
                            <div class=" form-group">
                                <textarea class="form-control" formControlName="optionOne"
                                    placeholder="Ingrese la primera opción de respuesta" rows="2" required></textarea>
                            </div>
                            <ng-container *ngFor="let validation of validationMessages.optionOne">
                                <small class="d-block text-danger"
                                    *ngIf="this.quizForm.controls.questions['controls'][0].get('optionOne').hasError(validation.type) &&
                    (this.quizForm.controls.questions['controls'][0].get('optionOne').dirty || this.quizForm.controls.questions['controls'][0].get('optionOne').touched) ">
                                    {{validation.message}}
                                </small>
                            </ng-container>

                            <label>2. Opción </label>
                            <div class=" form-group">
                                <textarea class="form-control" formControlName="optionTwo"
                                    placeholder="Ingrese la segunda opción de respuesta" rows="2"></textarea>
                            </div>

                            <ng-container *ngFor="let validation of validationMessages.optionTwo">
                                <small class="d-block text-danger"
                                    *ngIf="this.quizForm.controls.questions['controls'][0].get('optionTwo').hasError(validation.type) &&
                    (this.quizForm.controls.questions['controls'][0].get('optionTwo').dirty || this.quizForm.controls.questions['controls'][0].get('optionTwo').touched) ">
                                    {{validation.message}}
                                </small>
                            </ng-container>


                            <label>3. Opción </label>
                            <div class=" form-group">
                                <textarea class="form-control" formControlName="optionThree"
                                    placeholder="Ingrese la tercera opción de respuesta" rows="2"></textarea>
                            </div>

                            <label>4. Opción</label>
                            <div class=" form-group">
                                <textarea class="form-control" formControlName="optionFour"
                                    placeholder="Ingrese la cuarta opción de respuesta" rows="2"></textarea>
                            </div>

                            <label> Respuesta correcta </label>
                            <select class="form-select form-group d-block" formControlName="answer" size="4">
                                <option value="optionOne">Opción 1</option>
                                <option value="optionTwo">Opción 2</option>
                                <option value="optionThree">Opción 3</option>
                                <option value="optionFour">Opción 4</option>
                            </select>


                            <button (click)="removeItem(i)" class=" btn btn-simple btn-danger" type="button">
                                Eliminar pregunta
                            </button>
                        </fieldset>



                    </div>
                    <button (click)="addNewItem()" class=" btn btn-fill btn-primary" type="button">
                        Agregar pregunta
                    </button>

                </div>

            </div>
            <div class=" col-md-6">

                <label> Curso </label>
                <select class="form-select form-group d-block" formControlName="course" size="4">
                    <option *ngFor="let c of courses" [value]="c?.id">{{ c?.title | titlecase}}</option>
                </select>
                <ng-container *ngFor="let validation of validationMessages.course">
                    <small class="text-danger d-block" *ngIf="quizForm.get('course').hasError(validation.type) &&
                                (quizForm.get('course').dirty || quizForm.get('course').touched) ">
                        {{validation.message}}
                    </small>
                </ng-container>

                <ng-container *ngIf="errorMessage">
                    <small class="text-danger" *ngFor="let e of errorMessage | keyvalue">{{e.value}}</small> <br>
                </ng-container>

                <div class="mt-3">
                    <h6>Instrucciones</h6>
                    <small>Hemos llegado al último paso, agrega cuantas preguntas consideres necesarias al exámen, no
                        olvides elegir cuál de las 4 opciones es la correcta.
                    </small>
                </div>
                <div class="mt-3">
                    <h6>Restricciones</h6>
                    <small class="d-block">Las opciones uno y dos son obligatorias.</small>
                    <small class="d-block">Es obligatorio elegir el <strong>curso</strong> al que asignaremos la
                        pregunta.</small>
                </div>

            </div>

            <div class="col-sm-12 mb-5 mb-5">
                <button class=" btn btn-fill btn-secondary" [disabled]="quizForm.invalid" type="submit">
                    Enviar
                </button>
                <button class=" btn btn-fill btn-danger ml-2" (click)="cancelCreate()" type="button">
                    Cancelar
                </button>
            </div>

        </div>

    </form>
</div>