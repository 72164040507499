/* src/app/features/admin/components/sidebar/sidebar.component.css */
.sidebar-wrapper {
  width: 255px !important;
}
a,
p,
i,
div,
ul,
li,
span {
  color: #000 !important;
  font-weight: 600 !important;
}
::-webkit-scrollbar {
  display: none;
}
.logo {
  border-bottom: 1px solid #000 !important;
}
.separator {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #000;
}
.sidebar .nav li > a,
.off-canvas-sidebar .nav li > a {
  font-size: 0.5rem !important;
  padding: 0px !important;
}
/*# sourceMappingURL=sidebar.component.css.map */
