import { ɵgetAllInstancesOf, ɵAPP_CHECK_PROVIDER_NAME, ɵgetDefaultInstanceOf, ɵAppCheckInstances, VERSION, ɵAngularFireSchedulers, ɵzoneWrap } from '@angular/fire';
export { ɵAppCheckInstances as AppCheckInstances } from '@angular/fire';
import { timer, from } from 'rxjs';
import { concatMap, distinct } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';
import * as i0 from '@angular/core';
import { InjectionToken, isDevMode, Optional, PLATFORM_ID, NgModule, NgZone, Injector } from '@angular/core';
import { FirebaseApp, FirebaseApps } from '@angular/fire/app';
import { registerVersion } from 'firebase/app';
import { getLimitedUseToken as getLimitedUseToken$1, getToken as getToken$1, initializeAppCheck as initializeAppCheck$1, onTokenChanged as onTokenChanged$1, setTokenAutoRefreshEnabled as setTokenAutoRefreshEnabled$1 } from 'firebase/app-check';
export * from 'firebase/app-check';

class AppCheck {
    constructor(appCheck) {
        return appCheck;
    }
}
const appCheckInstance$ = timer(0, 300).pipe(concatMap(() => from(ɵgetAllInstancesOf(ɵAPP_CHECK_PROVIDER_NAME))), distinct());

const PROVIDED_APP_CHECK_INSTANCES = new InjectionToken('angularfire2.app-check-instances');
function defaultAppCheckInstanceFactory(provided, defaultApp) {
    const defaultAppCheck = ɵgetDefaultInstanceOf(ɵAPP_CHECK_PROVIDER_NAME, provided, defaultApp);
    return defaultAppCheck && new AppCheck(defaultAppCheck);
}
const LOCALHOSTS = ['localhost', '0.0.0.0', '127.0.0.1'];
const isLocalhost = typeof window !== 'undefined' && LOCALHOSTS.includes(window.location.hostname);
function appCheckInstanceFactory(fn) {
    return (zone, injector, platformId) => {
        // Node should use admin token provider, browser devmode and localhost should use debug token
        if (!isPlatformServer(platformId) && (isDevMode() || isLocalhost)) {
            globalThis.FIREBASE_APPCHECK_DEBUG_TOKEN ??= true;
        }
        const appCheck = zone.runOutsideAngular(() => fn(injector));
        return new AppCheck(appCheck);
    };
}
const APP_CHECK_INSTANCES_PROVIDER = {
    provide: ɵAppCheckInstances,
    deps: [
        [new Optional(), PROVIDED_APP_CHECK_INSTANCES],
    ]
};
const DEFAULT_APP_CHECK_INSTANCE_PROVIDER = {
    provide: AppCheck,
    useFactory: defaultAppCheckInstanceFactory,
    deps: [
        [new Optional(), PROVIDED_APP_CHECK_INSTANCES],
        FirebaseApp,
        PLATFORM_ID,
    ]
};
class AppCheckModule {
    constructor() {
        registerVersion('angularfire', VERSION.full, 'app-check');
    }
    static ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "16.2.10", ngImport: i0, type: AppCheckModule, deps: [], target: i0.ɵɵFactoryTarget.NgModule });
    static ɵmod = i0.ɵɵngDeclareNgModule({ minVersion: "14.0.0", version: "16.2.10", ngImport: i0, type: AppCheckModule });
    static ɵinj = i0.ɵɵngDeclareInjector({ minVersion: "12.0.0", version: "16.2.10", ngImport: i0, type: AppCheckModule, providers: [
            DEFAULT_APP_CHECK_INSTANCE_PROVIDER,
            APP_CHECK_INSTANCES_PROVIDER,
        ] });
}
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "16.2.10", ngImport: i0, type: AppCheckModule, decorators: [{
            type: NgModule,
            args: [{
                    providers: [
                        DEFAULT_APP_CHECK_INSTANCE_PROVIDER,
                        APP_CHECK_INSTANCES_PROVIDER,
                    ]
                }]
        }], ctorParameters: function () { return []; } });
function provideAppCheck(fn, ...deps) {
    return {
        ngModule: AppCheckModule,
        providers: [{
                provide: PROVIDED_APP_CHECK_INSTANCES,
                useFactory: appCheckInstanceFactory(fn),
                multi: true,
                deps: [
                    NgZone,
                    Injector,
                    PLATFORM_ID,
                    ɵAngularFireSchedulers,
                    FirebaseApps,
                    ...deps,
                ]
            }]
    };
}

// DO NOT MODIFY, this file is autogenerated by tools/build.ts
const getLimitedUseToken = ɵzoneWrap(getLimitedUseToken$1, true);
const getToken = ɵzoneWrap(getToken$1, true);
const initializeAppCheck = ɵzoneWrap(initializeAppCheck$1, true);
const onTokenChanged = ɵzoneWrap(onTokenChanged$1, true);
const setTokenAutoRefreshEnabled = ɵzoneWrap(setTokenAutoRefreshEnabled$1, true);

/**
 * Generated bundle index. Do not edit.
 */

export { AppCheck, AppCheckModule, appCheckInstance$, getLimitedUseToken, getToken, initializeAppCheck, onTokenChanged, provideAppCheck, setTokenAutoRefreshEnabled };

