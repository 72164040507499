<div class="content">
  <ol class="breadcrumb bg-transparent ml-3">
    <li class="breadcrumb-item">Academia</li>
    <li class="breadcrumb-item">Retiros y comisiones</li>
  </ol>

  <div class="row">
    <div class=" ml-3 col-12 shadow ">
      <button class="btn btn-primary " >Retirar</button>
    </div>
  </div>

</div>
