@charset "UTF-8";

/* src/app/features/funding-program/components/checkout/checkout.component.scss */
.shadow {
  box-shadow: 0px 0px 18px 0.2px rgba(0, 0, 0, 0.15) !important;
  border-radius: 10px;
}
.card-title {
  color: var(--secondary) !important;
  font-weight: 600 !important;
  border-bottom: 2px solid #4be8e6;
}
.container-credit-card {
  border: 2px solid #4be8e6;
  border-radius: 2px;
}
.card {
  border-bottom: 3px solid var(--secondary);
}
.card-title {
  color: var(--secondary) !important;
  font-weight: 600 !important;
}
.container-info {
  border-bottom: 1px solid #ccc;
}
.bg-label {
  border-radius: 5px;
  background-color: rgba(204, 204, 204, 0.8);
}
.btn-md {
  color: var(--secondary);
  background: #4be8e6 !important;
  background-image: #4be8e6 !important;
  background-color: #4be8e6 !important;
}
.modal-body {
  max-width: 600px;
  overflow-y: auto;
}
/*# sourceMappingURL=checkout.component.css.map */
