<div class="content">
  <ol class="breadcrumb bg-transparent ml-3">
    <li class="breadcrumb-item">Academia</li>
    <li class="breadcrumb-item text-primary">
      <a routerLink="self-management/home"> Inicio </a>
    </li>
    <li class="breadcrumb-item text-primary">
      <a routerLink="/funding-program/create-order"> Nuevo Paquete </a>
    </li>
    <li class="breadcrumb-item">Pago</li>
  </ol>
  <div class="row mx-3">
    <div class="col-lg-10 col-md-6">
      <div class="card card-stats shadow text-center">
        <div class="card-body">
          <div class="row container-title">
            <div class="col-8">
              <h2 class="card-title mt-2 mb-1 text-left">
                Datos de Facturación | Ordén Recibida
              </h2>
            </div>
            <div class="col-4">
              <img
                src="https://res.cloudinary.com/ddw7odxzr/image/upload/v1719765038/RC_11326_Logo_Final_Start_Academy_Alex_Logotipo_Negativo_ku9htb.png"
                alt="" width="150px" />
            </div>
          </div>
          <div class="row mt-3 mb-3 pb-3 container-info">
            <div class="col-7">
              <div class="row">
                <div class="col-5">
                  <p class="text-left">
                    <strong> Número de órden: </strong>
                    <span> {{ selectedOrder.id }} </span>
                  </p>
                </div>
                <div class="col-7">
                  <p class="text-left">
                    <strong> Telefono:</strong>
                    <span> {{ selectedOrder.phone_number }} </span>
                  </p>
                  <!-- <p class="text-left" > <strong> Código de Referido: </strong> 
                    <span>  {{ selectedOrder.referral_code | uppercase }}</span> 
                  </p>  -->
                </div>
                <!-- <div class="col-5">
                  <p class="text-left">
                    <strong> Típo de cuenta: </strong>
                    <span> {{ selectedOrder.package_type | uppercase }}</span>
                  </p>
                </div> -->
                <!-- <div class="col-7">
                  <p class="text-left">
                    <strong> Plataforma: </strong>
                    <span> MetaTrader 5 </span>
                  </p>
                </div> -->
                <div class="col-5">
                  <p class="text-left">
                    <strong> Nombre: </strong>
                    <span>
                      {{
                      selectedOrder.first_name + " " + selectedOrder.last_name
                      }}</span>
                  </p>
                </div>
                <div class="col-7">
                  <p class="text-left">
                    <strong> Ciudad:</strong>
                    <span> {{ selectedOrder.city }} </span>
                  </p>
                  <!--  <p class="text-left" > <strong> Dirección: </strong> 
                    <span> av 100pre viva 123 </span> 
                  </p>  -->
                </div>
                <div class="col-5">
                  <p class="text-left">
                    <strong> País: </strong>
                    <span> {{ selectedOrder.country }}</span>
                  </p>
                </div>
                <div class="col-7"></div>
                <div class="col-5">
                  <p class="text-left">
                    <strong> Código Postal: </strong>
                    <span> {{ selectedOrder.postal_code }} </span>
                  </p>
                </div>
                <div class="col-7"></div>
              </div>
            </div>

            <div class="col-5">
              <div class="row bg-label mt-3 mb-2 mr-2">
                <div class="col-2 my-auto">
                  <img src="https://res.cloudinary.com/app-intcapex-com/image/upload/v1682476124/email_b0aiqe.svg" />
                </div>
                <div class="col-10">
                  <div class="row">
                    <p class="text-left"><strong> Correo: </strong></p>
                  </div>
                  <div class="row">
                    <span class="text-left"> {{ selectedOrder.email }}</span>
                  </div>
                </div>
              </div>
              <div class="row bg-label mt-2 mr-2">
                <div class="col-2 my-auto">
                  <img
                    src="https://res.cloudinary.com/app-intcapex-com/image/upload/v1682476125/valor-challenge_uyqzmc.svg"
                    alt="" />
                </div>
                <div class="col-10">
                  <div class="row">
                    <p class="text-left"><strong>Valor paquete:</strong></p>
                  </div>
                  <div class="row">
                    <span class="text-right">
                      {{ "$" + selectedOrder?.value + " USD" }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-8 col-sm-12">
      <div class="card card-stats shadow text-center">
        <form [formGroup]="criptoform" (ngSubmit)="payWithCripto(cryptoModaltemplate)">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="row container-title">
                  <div class="col-11 text-left mx-auto">
                    <h2 class="card-title pb-2">
                      Pagar con <strong>Cripto</strong>
                    </h2>
                  </div>
                </div>
                <div class="form-group">
                  <label for="currency2">
                    Moneda: {{ currencyField?.value }}
                  </label>
                  <select formControlName="currency2" class="form-control" id="currency2">
                    <option value="" selected disabled>Choose Tagging</option>
                    <option *ngFor="let c of currencies" [value]="c?.symbol">
                      {{
                      (c?.name | titlecase) + " - " + (c?.symbol | uppercase)
                      }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="card-footer">
              <button class="btn btn-secondary btn-lg mb-3" type="submit" [disabled]="criptoform.invalid">
                Pagar con cripto
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- MODAL -->
<ng-template #cryptoModaltemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Coinpayments</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-4">
        <p class="title">Precio:</p>
        <p class="title">TXN id:</p>
        <p class="title">Bloques:</p>
        <p class="title">Wallet:</p>
      </div>
      <div class="col-7 mr-2">
        <p>{{ coinpaymentResponse?.amount }}</p>
        <p>{{ coinpaymentResponse?.txn_id }}</p>
        <p>{{ coinpaymentResponse?.confirms_needed }}</p>
        <p>{{ coinpaymentResponse?.address }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <p class="title">QR:</p>
      </div>
      <div class="col-8">
        <img [src]="coinpaymentResponse?.qrcode_url" alt="" srcset="" />
      </div>
    </div>
    <div class="row">
      <div class="col-8 mx-auto">
        <a [href]="coinpaymentResponse?.checkout_url" class="btn btn-secondary btn-lg mb-3" type="submit"
          target="_blank">
          Pagar
        </a>
        <a [href]="coinpaymentResponse?.status_url" class="btn btn-md btn-lg mb-3" type="submit" target="_blank">
          Estado
        </a>
      </div>
    </div>
    <div class="row">
      <h4>
        El pago con crypto tarda algo de tiempo en validarce, se te notificara
        por correo cuando se valide.
      </h4>
      <div class="row mx-auto">
        <a routerLink="/self-management/home" class="btn btn-md btn-lg mt-3" type="submit">
          Volver al Inicio
        </a>
      </div>
    </div>
  </div>
</ng-template>

<!-- <div class="content">
  <ol class="breadcrumb bg-transparent ml-3">
    <li class="breadcrumb-item">Academia</li>
    <li class="breadcrumb-item text-primary">
      <a routerLink="/self-management/home"> Inicio </a>
    </li>
    <li class="breadcrumb-item text-primary">
      <a routerLink="/self-management/orders"> Ordenes </a>
    </li>
    <li class="breadcrumb-item">Pago</li>
  </ol> 
  <div class="col-lg-5 col-md-5">
    <div class="card card-stats shadow text-center">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <h3 class="card-title">Pagar ordén 123456 ICEX AG</h3>
            <div *ngIf="invalidError" style="color: red">
              {{ invalidError.message }}
            </div>

            <stripe-card
              #stripeCard
              (catch)="onStripeError($event)"
              [(invalid)]="invalidError"
              (cardMounted)="cardCaptureReady = true"
              (paymentMethodChange)="setPaymentMethod($event)"
              (tokenChange)="setStripeToken($event)"
              (sourceChange)="setStripeSource($event)"
              [options]="cardOptions"
            ></stripe-card>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="card-footer">
          <button
            class="btn btn-secondary btn-lg mb-3"
            (click)="stripeCard.createToken($event)"
          >
            Pagar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
 -->
