<div class="content">
    <ol class="breadcrumb bg-transparent ml-3">
      <li class="breadcrumb-item">Academia</li>
      <li class="breadcrumb-item">Wallet</li>
    </ol>
  
    <div class="row">
      <div class=" ml-3 col-12 shadow ">
        <p>Informacion de la wallet</p>
        <button class="btn btn-primary " >Retirar</button>
      </div>
    </div>
  
  </div>
  