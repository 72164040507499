/* src/app/features/teacher/components/create-topics/create-topics.component.scss */
select {
  appearance: none;
  background-color: transparent;
  border: none;
  color: var(--primary);
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
}
.card-title {
  font-size: 3em !important;
  font-weight: 600 !important;
  color: var(--secondary) !important;
}
.mt-40 {
  margin-top: 50px !important;
  font-weight: 500 !important;
  color: white !important;
}
.card-subtitle {
  font-size: 1.5em !important;
  font-weight: 600 !important;
  color: var(--secondary) !important;
}
.form-control {
  border-color: var(--secondary);
  border-width: 1px !important;
  border-radius: 3px !important;
}
h6 {
  color: var(--secondary) !important;
}
small,
label {
  color: var(--default) !important;
}
.text-danger {
  color: var(--danger) !important;
}
.text-respuesta {
  color: var(--primary) !important;
  font-size: 1.2em !important;
}
/*# sourceMappingURL=create-topics.component.css.map */
