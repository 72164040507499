<div class="content" [ngClass]="{
    'class-user': userDataService?.userData$?.value?.rol === 'student'
  }">
  <ol class="breadcrumb bg-transparent ml-3">
    <li class="breadcrumb-item text-primary">
      <a routerLink="/home"> Inicio </a>
    </li>
    <li class="breadcrumb-item">Profile</li>
  </ol>
  <div class="offset-md-1 col-md-7">
    <h4 class="text-secondary">{{ rol | titlecase }}</h4>
    <h1 class="text-secondary title">
      {{ userDataService?.userData$?.value.first_name | uppercase }}
    </h1>
  </div>
  <div class="row">
    <div class="offset-md-1 col-md-7">
      <div class="card shadow">
        <div class="card-header">
          <h4 class="title">Editar perfil</h4>
        </div>
        <div class="card-body">
          <form class="form" [formGroup]="updateForm" (ngSubmit)="updateUser(updateForm.value)" role="form">
            <div class="row">
              <div class="col-md-6 pr-md-1">
                <div class="form-group">
                  <label> Nombre </label>
                  <input class="form-control text-uppercase" type="text" placeholder="Nombre"
                    [(ngModel)]="userDataService?.userData$?.value.first_name" formControlName="first_name" />
                </div>
                <ng-container *ngFor="let validation of validationMessages.first_name">
                  <small class="text-danger d-block" *ngIf="
                      updateForm.get('first_name').hasError(validation.type) &&
                      (updateForm.get('first_name').dirty ||
                        updateForm.get('first_name').touched)
                    ">
                    {{ validation.message }}
                  </small>
                </ng-container>
              </div>
              <div class="col-md-6 pl-md-1">
                <div class="form-group">
                  <label> Apellido </label>
                  <input class="form-control text-uppercase" type="text"
                    [(ngModel)]="userDataService?.userData$?.value.last_name" placeholder="Apellido"
                    formControlName="last_name" />
                </div>
                <ng-container *ngFor="let validation of validationMessages.last_name">
                  <small class="text-danger d-block" *ngIf="
                      updateForm.get('last_name').hasError(validation.type) &&
                      (updateForm.get('last_name').dirty ||
                        updateForm.get('last_name').touched)
                    ">
                    {{ validation.message }}
                  </small>
                </ng-container>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 pr-md-1">
                <div class="form-group">
                  <label> Dirección de Email </label>
                  <input class="form-control text-uppercase" [(ngModel)]="userDataService?.userData$?.value.username"
                    formControlName="email" placeholder="Email" type="email" (blur)="focus = false"
                    (focus)="focus = true" />
                </div>
                <ng-container *ngFor="let validation of validationMessages.email">
                  <small class="text-danger" *ngIf="
                      updateForm.get('email').hasError(validation.type) &&
                      (updateForm.get('email').dirty ||
                        updateForm.get('email').touched)
                    ">
                    {{ validation.message }}
                  </small>
                </ng-container>
              </div>
              <div class="col-md-6 pl-md-1">
                <div class="form-group">
                  <label> Fecha de nacimiento </label>
                  <input class="form-control text-uppercase" [(ngModel)]="userDataService?.userData$?.value.birth_date"
                    type="date" class="form-control" formControlName="birth_date" id="birth_date" id="birth_date"
                    (blur)="focus = false" (focus)="focus = true" />
                </div>
                <ng-container *ngFor="let validation of validationMessages.birth_date">
                  <small class="form-text text-danger" *ngIf="
                      updateForm.get('birth_date').hasError(validation.type) &&
                      (updateForm.get('birth_date').dirty ||
                        updateForm.get('birth_date').touched)
                    ">
                    {{ validation.message }}
                  </small>
                </ng-container>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 pr-md-1">
                <div class="form-group">
                  <label> DNI / C.C / PASAPORTE </label>
                  <input class="form-control text-uppercase" [(ngModel)]="
                      userDataService?.userData$?.value.identity_card
                    " formControlName="identity_card" id="identity_card" id="identity_card"
                    placeholder="Escriba su documento de identidad" type="text" (blur)="focus = false"
                    (focus)="focus = true" />
                </div>
                <ng-container *ngFor="let validation of validationMessages.identity_card">
                  <small class="form-text text-danger" *ngIf="
                      updateForm
                        .get('identity_card')
                        .hasError(validation.type) &&
                      (updateForm.get('identity_card').dirty ||
                        updateForm.get('identity_card').touched)
                    ">
                    {{ validation.message }}
                  </small>
                </ng-container>
              </div>
              <div class="col-md-6 pl-md-1">
                <div class="form-group">
                  <label> Teléfono </label>
                  <input class="form-control text-uppercase" placeholder="Teléfono"
                    [(ngModel)]="userDataService?.userData$?.value.phone_number" formControlName="phone_number"
                    type="tel" (blur)="focus = false" (focus)="focus = true" />
                </div>
                <ng-container *ngFor="let validation of validationMessages.phone">
                  <small class="text-danger" *ngIf="
                      updateForm
                        .get('phone_number')
                        .hasError(validation.type) &&
                      (updateForm.get('phone_number').dirty ||
                        updateForm.get('phone_number').touched)
                    ">
                    {{ validation.message }}
                  </small>
                </ng-container>
              </div>
            </div>
            <ng-container *ngIf="errorMessage">
              <small class="text-danger" *ngFor="let e of errorMessage | keyvalue">{{ e.value }}</small>
              <br />
            </ng-container>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-6 pr-md-1">
                  <button class="btn btn-fill btn-primary" [disabled]="updateForm.invalid" type="submit">
                    GUARDAR
                  </button>
                </div>
                <div class="pull-right col-md-6 pl-md-1" *ngIf="showButtonPassword">
                  <button type="button" class="btn btn-link text-primary text-uppercase" (click)="showUpdatePassword()">
                    CAMBIAR CONTRASEÑA
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="card shadow" *ngIf="showPasswordField">
        <div class="card-header">
          <h4 class="title">CAMBIAR CONTRASEÑA</h4>
        </div>
        <div class="card-body">
          <form class="form" [formGroup]="updatePasswordForm" (ngSubmit)="updatePassword(updatePasswordForm.value)"
            role="form">
            <div class="row">
              <div class="col-md-6 pr-md-1">
                <div class="form-group">
                  <label> Contraseña actual </label>
                  <input class="form-control" type="password" placeholder="Contraseña actual"
                    formControlName="old_password" />
                </div>
              </div>
              <div class="col-md-6 pl-md-1">
                <div class="form-group">
                  <label> Nueva contraseña </label>
                  <input class="form-control" type="password" placeholder="Nueva contraseña"
                    formControlName="password" />
                </div>
              </div>
              <ng-container *ngIf="errorMessage">
                <small class="text-danger" *ngFor="let e of errorPassMessage | keyvalue">{{ e.value }}</small>
                <br />
              </ng-container>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-6 pr-md-1">
                  <button class="btn btn-fill btn-primary" [disabled]="updatePasswordForm.invalid" type="submit">
                    Actualizar
                  </button>
                </div>
                <div class="col-md-6 pl-md-1">
                  <button class="btn btn-fill btn-danger" (click)="showUpdatePassword()">
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card card-user shadow">
        <div class="card-body p-0 pt-3 pb-3">
          <p class="card-text"></p>
          <div class="author">
            <a href="javascript:void(0)">
              <app-picture-upload></app-picture-upload>

              <h5 class="title text-secondary">
                {{ first_name | titlecase }} {{ last_name | titlecase }}
              </h5>
            </a>
          </div>
          <div class="card-description" style="text-align: center">
            <p class="description text-primary">{{ rol | titlecase }}</p>
            <p>{{ username }}</p>
            <!-- <p class="description">{{ userDataService?.userData$?.value.subscription | titlecase}}</p> -->
            <p class="description" *ngIf="
                userDataService?.userData$?.value.subscription === 'full';
                else elseTemplate
              ">
              Start Academy
            </p>
            <ng-template #elseTemplate>
              <p class="description">
                {{ userDataService?.userData$?.value.subscription | titlecase }}
              </p>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="offset-md-1 col-md-10">
    <div class="col-12">
      <h2 class="mt-2 mb-0 text-secondary">Cursos aprobados</h2>
      <hr style="width: 100%" color="primary" />
    </div>

    <div class="row">
      <ng-container *ngIf="approvedCourses.length < 1">
        <div class="col-lg-6 col-md-3 col-sm-12 col-sm-12">
          <div class="card shadow">
            <div class="card-body">
              <h5 class="card-title text-center">
                Aún no tienes aprobado ningún curso
              </h5>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngFor="let c of approvedCourses">
        <div class="col-lg-3 col-md-3 col-sm-12 col-sm-12">
          <div class="card">
            <img class="card-img-top" [src]="c?.path_preview_image" alt="Card image cap" />
            <div class="card-body">
              <h5 class="card-title text-center">
                {{
                (c.title.length > 20 ? c.title.slice(0, 18) + "..." : c.title)
                | uppercase
                }}
              </h5>
              <button class="btn btn-simple btn-secondary btn-block" type="button" style="color:#d0ad50 !important" (click)="generateCertificate(c.id)">
                DESCARGAR CERTIFICADO
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
