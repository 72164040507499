<div class="content">
  <ol class="breadcrumb bg-transparent ml-3">
    <li class="breadcrumb-item">Funding Program</li>
    <li class="breadcrumb-item">Area Trading</li>
  </ol>
  <h3 class="ml-5">Empieza a operar</h3>
  <div class="row ml-4">
    <ng-container *ngIf="packDemo">
      <div class="col-lg-10">
        <div class="card p-3 mb-4 shadow">
          <div class="row">
            <div class="col-10">
              <h3 class="card-title pb-3">Icex Funding Challenge DEMO</h3>
            </div>
            <div class="col-2">
              <p class="card-text mr-auto">
                Fin: {{ packDemo?.created_at | date : "dd/MM/yyyy" }}
              </p>
            </div>
          </div>
          <div
            class="row d-flex justify-content-between align-items-center border mx-2"
          >
            <div class="col-6 my-auto card-body">
              <div class="row mt-2">
                <div class="col-10">
                  <p class="card-text mr-auto">Login: {{ packDemo?.name }}</p>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-10">
                  <p class="card-text mr-auto">
                    Balance:
                    <strong>{{ "$ " + packDemo?.mt_balance + " USD" }} </strong>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-6 card-body">
              <div
                class="row d-flex justify-content-between align-items-center mr-2 pr-2"
              >
                <div class="col-5">
                  <button
                    class="btn btn-lg btn-custom"
                    (click)="show(template)"
                  >
                    Credenciales
                  </button>
                </div>
                <div class="col-5">
                  <button
                    class="btn btn-lg btn-custom"
                    routerLink="/funding-program/metrics"
                  >
                    Metricas
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row d-flex justify-content-between align-items-center card-footer"
          >
            <!--  <div class="col-4">
              <p class="card-text text-center rounded-pill p-2">
                Estado:
                
              </p>
            </div> -->
            <div class="col-6">
              <!-- <button class="btn btn-secondary btn-block my-auto">
                Retiros
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="col-lg-10" *ngFor="let pack of myPackages; let i = index">
      <div class="card p-3 mb-4 shadow">
        <div class="row">
          <div class="col-10">
            <h3 class="card-title pb-3">
              Icex Funding Challenge # {{ pack.id }}
            </h3>
          </div>
          <div class="col-2">
            <p class="card-text mr-auto">
              Fin: {{ pack.end_date | date : "dd/MM/yyyy" }}
            </p>
          </div>
        </div>
        <div
          class="row d-flex justify-content-between align-items-center border mx-2 pr-2"
        >
          <div class="col-6 my-auto card-body">
            <div class="row mt-2">
              <div class="col-10">
                <p class="card-text mr-auto">
                  Nombre de login: {{ pack.mt_login }}
                </p>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-10">
                <p class="card-text mr-auto">
                  Balance:
                  {{ "$" + pack.balance + " " + pack.currencies | uppercase }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-6 card-body" *ngIf="pack.status == 'active'">
            <div
              class="row d-flex justify-content-between align-items-center pr-3"
            >
              <div class="col-5">
                <button
                  class="btn btn-lg btn-custom"
                  (click)="showPack(template2, i)"
                >
                  Credenciales
                </button>
              </div>
              <div class="col-5">
                <button
                  class="btn btn-lg btn-custom"
                  routerLink="/funding-program/metrics"
                >
                  Metricas
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row d-flex justify-content-between align-items-center card-footer"
        >
          <div class="col-4">
            <p
              class="card-text text-center rounded-pill p-2"
              [ngClass]="{
                'bg-success': pack.status === 'active',
                'bg-danger': pack.status === 'closed',
                'bg-warning': pack.status === 'pending'
              }"
            >
              Estado:
              <span>{{ pack.status }}</span>
            </p>
          </div>
          <div class="col-6">
            <button
              *ngIf="pack.status == 'active'"
              class="btn btn-secondary btn-block my-auto"
              routerLink="/funding-program/withdrawals"
            >
              Retiros
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-6 mx-auto">
      <h2 class="mb-1 text-center">Funding Program</h2>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-6 mx-auto">
      <p class="text-center text-dark">
        El primer fondeo con un broker 100% Real! Aprovecha la enorme liquidez
        del Broker, tradea y retira los beneficios que te mereces.
      </p>
    </div>
  </div>
  <div class="row ml-4">
    <div class="col-lg-6">
      <div class="card card-stats shadow-custom text-center">
        <div class="card-body">
          <div class="row">
            <div class="col-8 mt-4 mb-2 mx-auto">
              <h3 class="card-subtitle">Icex Funding</h3>
            </div>
            <div class="col-8 container-card-title mx-auto">
              <h2 class="card-title pb-4">Challenge DEMO</h2>
            </div>
            <div class="col-8 card-text mx-auto mb-1">
              <p>Controla, fortalece y potencia tus habilidades</p>
            </div>
          </div>
          <div class="row">
            <div class="col-11 container-body mx-auto text-left">
              <p class="stats mt-4">
                Experimenta el trading sin poner en riesgo tu inversión
              </p>
              <div class="row">
                <div class="col-1 icon">
                  <img
                    src="https://res.cloudinary.com/ddw7odxzr/image/upload/v1696042643/webapp/ICONO-CHECK-11_cqgedh.svg"
                    alt=""
                  />
                </div>
                <div class="col-10 text-next-icon">
                  <p class="stats">
                    Escoge el balance con el que quieras empezar a operar
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-1 icon">
                  <img
                    src="https://res.cloudinary.com/ddw7odxzr/image/upload/v1696042643/webapp/ICONO-CHECK-11_cqgedh.svg"
                    alt=""
                  />
                </div>
                <div class="col-10 text-next-icon">
                  <p class="stats">Análisis de la cuenta</p>
                </div>
              </div>
              <div class="row">
                <div class="col-1 icon">
                  <img
                    src="https://res.cloudinary.com/ddw7odxzr/image/upload/v1696042643/webapp/ICONO-CHECK-11_cqgedh.svg"
                    alt=""
                  />
                </div>
                <div class="col-10 text-next-icon">
                  <p class="stats">15 Días de prueba</p>
                </div>
              </div>
              <div class="row">
                <div class="col-1 icon">
                  <img
                    src="https://res.cloudinary.com/ddw7odxzr/image/upload/v1696042643/webapp/ICONO-CHECK-11_cqgedh.svg"
                    alt=""
                  />
                </div>
                <div class="col-10 text-next-icon">
                  <p class="stats">Practica gratis</p>
                </div>
              </div>
              <div class="row">
                <div class="col-1 icon">
                  <img
                    src="https://res.cloudinary.com/ddw7odxzr/image/upload/v1696042643/webapp/ICONO-CHECK-11_cqgedh.svg"
                    alt=""
                  />
                </div>
                <div class="col-10 text-next-icon">
                  <p class="stats">
                    Contenido de respaldo
                    <br /><br />
                  </p>
                </div>
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
          <div class="row card-footer mt-4 mb-3">
            <div class="col-10 mx-auto">
              <p class="stats">
                Demuestra tus habilidades completando nuestras fases
              </p>
            </div>
            <div class="col-10 mx-auto">
              <button
                class="btn btn-secondary btn-lg mb-3"
                routerLink="/funding-program/create-demo"
              >
                Comenzar DEMO gratis
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card card-stats shadow-custom text-center">
        <div class="card-body">
          <div class="row">
            <div class="col-8 mt-4 mb-2 mx-auto">
              <h3 class="card-subtitle">Icex Funding</h3>
            </div>
            <div class="col-8 container-card-title mx-auto">
              <h2 class="card-title pb-4">Challenge</h2>
            </div>
            <div class="col-8 card-text mx-auto">
              <p>
                Opere hasta 500.000 usd con nuestro broker Euro street capital
                llc
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-11 container-body mx-auto text-left">
              <p class="stats mt-4">
                Demuestra tus destrezas de trading y empieza a retirar tu
                beneficio diario.
              </p>
              <div class="row">
                <div class="col-1 icon">
                  <img
                    src="https://res.cloudinary.com/ddw7odxzr/image/upload/v1696042643/webapp/ICONO-CHECK-11_cqgedh.svg"
                    alt=""
                  />
                </div>
                <div class="col-10 text-next-icon">
                  <p class="stats">
                    Escoge el balance con el que quieras empezar a operar
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-1 icon">
                  <img
                    src="https://res.cloudinary.com/ddw7odxzr/image/upload/v1696042643/webapp/ICONO-CHECK-11_cqgedh.svg"
                    alt=""
                  />
                </div>
                <div class="col-10 text-next-icon">
                  <p class="stats">Análisis de la cuenta</p>
                </div>
              </div>
              <div class="row">
                <div class="col-1 icon">
                  <img
                    src="https://res.cloudinary.com/ddw7odxzr/image/upload/v1696042643/webapp/ICONO-CHECK-11_cqgedh.svg"
                    alt=""
                  />
                </div>
                <div class="col-10 text-next-icon">
                  <p class="stats">Beneficio del 75%</p>
                </div>
              </div>
              <div class="row">
                <div class="col-1 icon">
                  <img
                    src="https://res.cloudinary.com/ddw7odxzr/image/upload/v1696042643/webapp/ICONO-CHECK-11_cqgedh.svg"
                    alt=""
                  />
                </div>
                <div class="col-10 text-next-icon">
                  <p class="stats">Retiros de beneficios diarios</p>
                </div>
              </div>
              <div class="row">
                <div class="col-1 icon">
                  <img
                    src="https://res.cloudinary.com/ddw7odxzr/image/upload/v1696042643/webapp/ICONO-CHECK-11_cqgedh.svg"
                    alt=""
                  />
                </div>
                <div class="col-10 text-next-icon">
                  <p class="stats">Contenido de respaldo</p>
                </div>
              </div>
              <div class="row">
                <div class="col-1 icon">
                  <img
                    src="https://res.cloudinary.com/ddw7odxzr/image/upload/v1696042643/webapp/ICONO-CHECK-11_cqgedh.svg"
                    alt=""
                  />
                </div>
                <div class="col-10 text-next-icon">
                  <p class="stats">Extensión gratuita de 15 días</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row card-footer mt-3 mb-3">
            <div class="col-10 mx-auto">
              <p class="stats">
                Demuestra tus habilidades completando nuestras fases
              </p>
            </div>
            <div class="col-10 mx-auto">
              <button
                class="btn btn-secondary btn-lg mb-3"
                routerLink="/funding-program/create-order"
              >
                Comenzar ICEX Challenge
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row ml-4">
    <div class="col-lg-12 col-md-12">
      <div class="card card-stats text-center shadow-custom">
        <div class="row">
          <div class="col-8 text-left ml-3 mt-2">
            <h3 class="card-title pb-3 pt-3">Objetivos del challenge</h3>
          </div>
        </div>
        <div class="row mb-3 mt-2">
          <div class="col-2 phase ml-4 my-auto p-2">
            <h3 class="mt-4">Fase 1</h3>
          </div>
          <div class="col-9 goals ml-2 p-2">
            <div class="row">
              <div class="col-4">
                <div class="row">
                  <div class="col-3">
                    <img
                      class=""
                      src="https://res.cloudinary.com/app-intcapex-com/image/upload/v1682476124/dias-trading_epogji.svg"
                      alt=""
                    />
                  </div>
                  <div class="col-9">
                    <p class="text-left">Días de trading 1 día mínimo</p>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="row">
                  <div class="col-3">
                    <img
                      src="https://res.cloudinary.com/app-intcapex-com/image/upload/v1682476125/restricciones_v7y2ag.svg"
                      alt=""
                    />
                  </div>
                  <div class="col-9">
                    <p class="text-left mt-2">Restricciones no</p>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="row">
                  <div class="col-3">
                    <img
                      src="https://res.cloudinary.com/app-intcapex-com/image/upload/v1682476124/drawdown-diario_w3dvpm.svg"
                      alt=""
                    />
                  </div>
                  <div class="col-9">
                    <p class="text-left mt-2">Drawdown diario 5%</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <div class="row">
                  <div class="col-3">
                    <img
                      src="https://res.cloudinary.com/app-intcapex-com/image/upload/v1682476124/drawdown-diario_w3dvpm.svg"
                      alt=""
                    />
                  </div>
                  <div class="col-9">
                    <p class="mt-3 text-left">Drawdown total 10%</p>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-2">
                    <img
                      src="https://res.cloudinary.com/app-intcapex-com/image/upload/v1682476125/valor-challenge_uyqzmc.svg"
                      alt=""
                    />
                  </div>
                  <div class="col-9">
                    <p class="mt-3 text-left">
                      Profit del 8% a lograr en 30 días.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4 mb-3">
          <div class="col-2 phase ml-4 my-auto p-2">
            <h3 class="mt-4">Fase 2</h3>
          </div>
          <div class="col-9 goals ml-2 p-2">
            <div class="row">
              <div class="col-4">
                <div class="row">
                  <div class="col-3">
                    <img
                      src="https://res.cloudinary.com/app-intcapex-com/image/upload/v1682476124/dias-trading_epogji.svg"
                      alt=""
                    />
                  </div>
                  <div class="col-9">
                    <p class="text-left">Días de trading 1 día mínimo</p>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="row">
                  <div class="col-3">
                    <img
                      src="https://res.cloudinary.com/app-intcapex-com/image/upload/v1682476125/restricciones_v7y2ag.svg"
                      alt=""
                    />
                  </div>
                  <div class="col-9">
                    <p class="text-left mt-2">Restricciones no</p>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="row">
                  <div class="col-3">
                    <img
                      src="https://res.cloudinary.com/app-intcapex-com/image/upload/v1682476124/drawdown-diario_w3dvpm.svg"
                      alt=""
                    />
                  </div>
                  <div class="col-9">
                    <p class="text-left mt-2">Drawdown diario 5%</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <div class="row">
                  <div class="col-3">
                    <img
                      src="https://res.cloudinary.com/app-intcapex-com/image/upload/v1682476124/drawdown-diario_w3dvpm.svg"
                      alt=""
                    />
                  </div>
                  <div class="col-9">
                    <p class="text-left mt-3">Drawdown total 10%</p>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-2">
                    <img
                      src="https://res.cloudinary.com/app-intcapex-com/image/upload/v1682476125/valor-challenge_uyqzmc.svg"
                      alt=""
                    />
                  </div>
                  <div class="col-9">
                    <p class="mt-3 text-left">
                      Profit del 5% a lograr en 60 días.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--  <div class="col-lg-12 col-md-12 card card-stats shadow-custom commissions">
    <div class="row">
      <div class="col-7">
        <h3 class=" card-subtitle mt-3 mb-2">
          Comisiona con International Capital Exchange
        </h3>
        <p class="text-light">
          Invita a tus amigos y empieza a generar ingresos extras. 
          recibe el 25% del valor del paquete por el primer referido, 
          un 25% por el segundo referido y un 100% por tu tercer referido 
        </p>
        <p class="mb-3 text-light" >
          Compártelo y empieza a comisionar con los paquetes de Academia
        </p>
      </div>
      <div class="col-5 my-auto mx-auto">
        <div class="">
          <p class="ref text-center">{{ referalCode }}</p>
        </div>
        <div class="">
          <button class="btn btn-ref  btn-block  " [cdkCopyToClipboard]="referalCode">
            Copiar
          </button>
        </div>
      </div>
    </div>
  </div> -->
</div>

<!-- MODAL -->
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Credenciales</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-4 my-auto">
        <p>Login:</p>
      </div>
      <div class="col-4 my-auto">
        <p>
          <strong> {{ packDemo?.mt_login }} </strong>
        </p>
      </div>
      <div class="col-4">
        <button
          class="btn btn-secondary btn-md"
          [cdkCopyToClipboard]="loginDemo"
          (click)="copied('login')"
        >
          {{ buttonTextLogin }}
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-4 my-auto">
        <p>Contraseña:</p>
      </div>
      <div class="col-4 my-auto">
        <p>
          <strong> {{ packDemo?.mt_password }} </strong>
        </p>
      </div>
      <div class="col-4">
        <button
          class="btn btn-secondary btn-md"
          [cdkCopyToClipboard]="passDemo"
          (click)="copied('pass')"
        >
          {{ buttonTextPass }}
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-4 my-auto">
        <p>Contraseña del Inversor:</p>
      </div>
      <div class="col-4 my-auto">
        <p>
          <strong> {{ packDemo?.mt_password_investor }} </strong>
        </p>
      </div>
      <div class="col-4">
        <button
          class="btn btn-secondary btn-md"
          [cdkCopyToClipboard]="invesPassDemo"
          (click)="copied('passInves')"
        >
          {{ buttonTextPassInves }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!-- -------------Modal 2----------------- -->

<ng-template #template2>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Credenciales</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 mx-auto" *ngIf="packSeleted?.mt_login == ''">
        <p>
          <strong>
            En breve un administrador activará tus credenciales.
          </strong>
        </p>
      </div>
      <div class="col-4 my-auto">
        <p>Login:</p>
      </div>
      <div class="col-4 my-auto">
        <p>
          <strong> {{ packSeleted?.mt_login }} </strong>
        </p>
      </div>
      <div class="col-4">
        <button
          class="btn btn-secondary btn-md"
          [cdkCopyToClipboard]="loginPack"
          (click)="copied('login')"
        >
          {{ buttonTextLogin }}
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-4 my-auto">
        <p>Contraseña:</p>
      </div>
      <div class="col-4 my-auto">
        <p>
          <strong> {{ packSeleted?.mt_password }} </strong>
        </p>
      </div>
      <div class="col-4">
        <button
          class="btn btn-secondary btn-md"
          [cdkCopyToClipboard]="passPack"
          (click)="copied('pass')"
        >
          {{ buttonTextPass }}
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-4 my-auto">
        <p>Contraseña del Inversor:</p>
      </div>
      <div class="col-4 my-auto">
        <p>
          <strong> {{ packSeleted?.mt_password_investor }} </strong>
        </p>
      </div>
      <div class="col-4">
        <button
          class="btn btn-secondary btn-md"
          [cdkCopyToClipboard]="invesPassPack"
          (click)="copied('passInves')"
        >
          {{ buttonTextPassInves }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!-- -------------Modal 3----------------- -->

<ng-template #template3>
  <div class="modal-header">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <h2><span class="badge badge-primary">Proximamente</span></h2>
    </div>
  </div>
</ng-template>
