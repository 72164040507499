/* src/app/features/self-management/components/orders/orders.component.scss */
.shadow {
  box-shadow: 0px 0px 18px 0.2px rgba(0, 0, 0, 0.15) !important;
  border-radius: 10px;
}
.card {
  border-bottom: 3px solid var(--secondary);
}
.container-title {
  padding-bottom: 1em;
  border-bottom: 3px solid #4be8e6;
}
.card-title {
  color: var(--secondary) !important;
  font-weight: 600 !important;
}
.container-info {
  border-bottom: 1px solid #ccc;
}
.bg-label {
  border-radius: 5px;
  background-color: rgba(204, 204, 204, 0.8);
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: var(--secondary) !important;
  content: "/";
}
/*# sourceMappingURL=orders.component.css.map */
