/* src/app/shared/components/auth-navbar/auth-navbar.component.scss */
.navbar-collapse {
  display: none !important;
}
.navbar-toggler {
  display: none !important;
}
.logo {
  width: 50%;
}
/*# sourceMappingURL=auth-navbar.component.css.map */
