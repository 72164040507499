<div class="content">
    <ol class="breadcrumb bg-transparent ml-3">
     
      <li class="breadcrumb-item text-primary">
        <a routerLink="/self-management/home"> Academia </a>
      </li>
      <li class="breadcrumb-item">Corredores</li>
    </ol>
  
    <div class="row ml-3">
      <div class="col-12">
        Retiros Globales
      </div>
    </div>
  
  </div>