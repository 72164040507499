/* src/app/features/teacher/pages/admin-courses/admin-courses.component.scss */
.content {
  background-image: url(https://res.cloudinary.com/ddw7odxzr/image/upload/v1696042471/webapp/FONDO_zftidw.jpg) !important;
}
.shadow {
  border-radius: 10px;
}
.card {
  background-color: #ffffff !important;
  border-radius: 10px;
}
th {
  color: var(--secondary) !important;
}
.datatable-body-cell-label {
  color: black !important;
}
.card-title {
  font-size: 3em !important;
  font-weight: 600 !important;
  color: var(--secondary) !important;
}
.title-lg {
  font-size: 3em !important;
  font-weight: 600 !important;
  color: var(--secondary) !important;
}
.btn-green {
  color: green !important;
}
/*# sourceMappingURL=admin-courses.component.css.map */
