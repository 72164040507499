<div class=" card text-left">
    <form [formGroup]="moduleForm" (ngSubmit)="editarModule(moduleForm.value)" role="form">

        <div class=" card-body row">

            <div class="col-12">
                <h4 class=" card-title mt-5">Editar módulo</h4>
            </div>

            <div class="col-md-6">

                <label> Título </label>
                <div class=" form-group">
                    <input class=" form-control" formControlName="name" placeholder="Título del módulo" type="text"
                         required />
                </div>
                <ng-container *ngFor="let validation of validationMessages.title">
                    <small class="text-danger d-block" *ngIf="moduleForm.get('name').hasError(validation.type) &&
                                (moduleForm.get('name').dirty || moduleForm.get('name').touched) ">
                        {{validation.message}}
                    </small>
                </ng-container>
                <label> Curso </label>
                <select class="form-select form-group d-block" formControlName="course" size="4">
                    <option *ngFor="let c of courses" [value]="c?.id" >
                        {{ c?.title | titlecase}}</option>
                </select>
                <p class="mt-2 text-info"> Curso actual: {{currentCourse | titlecase}} </p>

                <ng-container *ngFor="let validation of validationMessages.course">
                    <small class="text-danger d-block" *ngIf="moduleForm.get('course').hasError(validation.type) &&
                                (moduleForm.get('course').dirty || moduleForm.get('course').touched) ">
                        {{validation.message}}
                    </small>
                </ng-container>

                <ng-container *ngIf="errorMessage">
                    <small class="text-danger" *ngFor="let e of errorMessage | keyvalue">{{e.value}}</small> <br>
                </ng-container>
            </div>

            <div class="col-md-6">
                <div class="mt-2">
                    <h6>Instrucciones</h6>
                    <small>Haz cometido un error, pero no hay problema, ingresa el título y curso del módulo nuevamente</small>
                </div>
                <div class="mt-3">
                    <h6>Restricciones</h6>
                    <small class="d-block">Ambos campos son <strong>obligatorios</strong>.</small>
                </div>
            </div>

            <div class="col-sm-12 mb-5 mb-5">
                <button class=" btn btn-fill btn-secondary" [disabled]="moduleForm.invalid" type="submit">
                    Enviar
                </button>
                <button class=" btn btn-fill btn-danger ml-2" (click)="cancelCreate()" type="button">
                    Cancelar
                </button>
            </div>

        </div>

    </form>
</div>