<nav class=" navbar navbar-expand-lg navbar-absolute fixed-top"
  [ngClass]="{'bg-white': isCollapsed === false, 'navbar-transparent': isCollapsed === true}">
  <div class=" container-fluid">
    <div class=" navbar-wrapper">
      <div class=" navbar-toggle d-inline">
        <button class=" navbar-toggler" type="button">
          <span class=" navbar-toggler-bar bar1">
          </span>
          <span class=" navbar-toggler-bar bar2">
          </span>
          <span class=" navbar-toggler-bar bar3">
          </span>
        </button>
      </div>
      <a class=" navbar-brand" href="https://sniperpro.digital/home">
        <img src="https://res.cloudinary.com/ddw7odxzr/image/upload/v1727228080/1_ppbczs.png" class="img-fluid logo"
          alt="logo" style=" width: 220px !important;">

      </a>
    </div>
    <button aria-label="Toggle navigation" class=" navbar-toggler" [attr.aria-expanded]="!isCollapsed"
      (click)="isCollapsed = !isCollapsed" id="navigation" type="button">
      <span class=" navbar-toggler-bar navbar-kebab">
      </span>
      <span class=" navbar-toggler-bar navbar-kebab">
      </span>
      <span class=" navbar-toggler-bar navbar-kebab">
      </span>
    </button>
    <div class=" navbar-collapse" [collapse]="isCollapsed" id="navigation">
      <ul class=" navbar-nav ml-auto">
        <li class=" nav-item">
          <a class=" nav-link text-primary" [routerLink]="['/dashboard']">
            <i class=" tim-icons icon-minimal-left">
            </i>
            Ir al Dashboard
          </a>
        </li>
        <li class=" nav-item">
          <a class=" nav-link" [routerLink]="['/sign-in']">
            <i class=" tim-icons icon-single-02">
            </i>
            Ingresar
          </a>
        </li>
        <li class=" nav-item">
          <a class=" nav-link" [routerLink]="['/sign-up']">
            <i class=" tim-icons icon-laptop">
            </i>
            Registro
          </a>
        </li>
        <li class=" nav-item">
          <a class=" nav-link" [routerLink]="['/forget-password']">
            <i class=" tim-icons icon-lock-circle">
            </i>
            Recuperar
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>