/* src/app/features/funding-program/components/my-packages/my-packages.component.scss */
.shadow {
  box-shadow: 0px 0px 18px 0.2px rgba(0, 0, 0, 0.15) !important;
  border-radius: 10px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #999999;
}
/*# sourceMappingURL=my-packages.component.css.map */
