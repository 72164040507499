<div class="content">
  <ol class="breadcrumb bg-transparent ml-3">
    <li class="breadcrumb-item text-primary"> <a routerLink="/funding-program/home">Funding Program</a> </li>
   <!--  <li class="breadcrumb-item text-primary">
      <a routerLink="/funding-program/home"> Inicio </a>
    </li> -->
    <li class="breadcrumb-item">Ordenes</li>
  </ol>

  

  <ng-container *ngIf="orders.length > 0; else elseTemplate">
    <div *ngFor="let i of orders" class="col-lg-10 col-sm-12">
      <div class="card card-stats shadow text-center ">
        <div class="card-body">
          <div class="row container-title ">
            <div class="col-5">
              <h2 class="card-title mt-2 mb-1 text-left">Ordén Recibida</h2> 
            </div>
            <div class="col-3 my-auto">
              <p class="rounded-pill p-1"
                [ngClass]="{
                  'bg-success': i?.status === 'active',
                  'bg-danger': i?.status === 'closed',
                  'bg-warning': i?.status === 'pending'
                }"
              >Estado: <span> {{i?.status}} </span> </p>
            </div>
            <div class="col-4">
              <img src="https://res.cloudinary.com/app-intcapex-com/image/upload/v1680689760/LOGO_LOGIN-30_xmssxz.svg" alt="" width="150px">
            </div>
          </div>
          <div class="row mt-3 mb-3 pb-3 container-info">
            <div class="col-6 ">
              <div class="row">
                <div class="col-12">
                  <p class="text-left" > <strong> Número de órden: </strong> 
                    <span> {{ i?.id }} </span>
                  </p> 
                </div> 
                <div class="col-12">
                  <p class="text-left" > <strong> Tamaño de la cuenta: </strong> 
                    <span> {{ '$ ' + i?.balance }} {{ i?.currencies | uppercase }}</span> 
                  </p> 
                </div>
                <div class="col-12">
                  <p class="text-left" > <strong> Típo de cuenta: </strong> 
                    <span> {{ i?.currencies | uppercase }}</span> 
                  </p> 
                </div>
                <div class="col-12">
                  <p class="text-left" > <strong> Plataforma: </strong> 
                    <span> MetaTrader 5 </span> 
                  </p> 
                </div>
                <div class="col-12">
                  <p class="text-left" > <strong> Nombre: </strong> 
                    <span> {{ i?.first_name + ' ' + i?.last_name  }}</span> 
                  </p> 
                </div>
        
              </div>
              
            </div>
            <div class="col-6">
              <div class="row bg-label mt-3 mb-2 mr-2  ">
                <div class="col-2 my-auto">
                  <img 
                  src="https://res.cloudinary.com/app-intcapex-com/image/upload/v1682476124/email_b0aiqe.svg" >
                </div>
                <div class="col-10">
                  <div class="row">
                    <p class="text-left" > <strong> Correo: </strong> </p>
                  </div>
                  <div class="row">
                    <span class="text-right" > {{ i?.email }}</span>
                  </div>
                </div>
              </div>
              <div class="row bg-label mt-2 mr-2">
                <div class="col-2 my-auto">
                  <img 
                    src="https://res.cloudinary.com/app-intcapex-com/image/upload/v1682476125/valor-challenge_uyqzmc.svg" 
                    alt="">
                </div>
                <div class="col-10">
                  <div class="row">
                    <p class="text-left" > <strong>Valor del Challenge:</strong> </p>
                  </div>
                  <div class="row">
                    <span class="text-right" > {{ '$' + i?.value + ' USD' }} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row"> 
            <div class="col-12">
              <div class="row">
                <div class="col-6 mx-auto">
                  <button *ngIf=" i?.status == 'closed' " 
                    class=" btn btn-secondary btn-lg"
                    routerLink="../checkout/{{ i?.id }}"
                  > Pagar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #elseTemplate>
    <p>No tienes ordenes generadas en este momento</p>
  </ng-template>
</div>
