/* src/app/features/teacher/components/edit-course/edit-course.component.scss */
.form-control {
  border-color: var(--secondary);
  border-width: 1px !important;
  border-radius: 3px !important;
}
.card-title {
  font-size: 3em !important;
  font-weight: 600 !important;
  color: var(--secondary) !important;
}
.mt-40 {
  margin-top: 50px !important;
  font-weight: 500 !important;
  color: white !important;
}
.card-subtitle {
  font-size: 1.5em !important;
  font-weight: 600 !important;
  color: var(--secondary) !important;
}
.active .bs-stepper-circle {
  background: var(--primary) !important;
  color: aliceblue !important;
}
.bs-stepper-label {
  color: aliceblue !important;
}
.bs-stepper-label:hover {
  color: aliceblue;
  background: var(--primary) !important;
}
.form-control {
  border-color: var(--secondary);
  border-width: 1px !important;
  border-radius: 3px !important;
}
h6 {
  color: var(--secondary) !important;
}
small,
label {
  color: var(--default) !important;
}
.text-danger {
  color: var(--danger) !important;
}
.text-respuesta {
  color: var(--primary) !important;
  font-size: 1.2em !important;
}
/*# sourceMappingURL=edit-course.component.css.map */
