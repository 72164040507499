@charset "UTF-8";

/* src/app/features/self-management/components/checkout/checkout.component.scss */
.content {
  background-image: url(https://res.cloudinary.com/ddw7odxzr/image/upload/v1696042471/webapp/FONDO_zftidw.jpg) !important;
}
.shadow {
  box-shadow: 0px 0px 18px 0.2px rgba(0, 0, 0, 0.15) !important;
  border-radius: 10px;
}
.card-title {
  color: var(--secondary) !important;
  font-weight: 600 !important;
  border-bottom: 2px solid black;
}
.container-credit-card {
  border: 2px solid black;
  border-radius: 2px;
}
.card {
  border-bottom: 3px solid var(--secondary);
}
.card-title {
  color: var(--secondary) !important;
  font-weight: 600 !important;
}
.container-info {
  border-bottom: 1px solid #ccc;
}
.bg-label {
  border-radius: 5px;
  background-color: rgba(204, 204, 204, 0.8);
}
.btn-md {
  color: var(--secondary);
  background: black !important;
  background-image: black !important;
  background-color: black !important;
}
.modal-body {
  max-width: 600px;
  overflow-y: auto;
}
/*# sourceMappingURL=checkout.component.css.map */
