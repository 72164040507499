/* src/app/features/self-management/self-management.component.scss */
.content {
  background-image: url(https://res.cloudinary.com/ddw7odxzr/image/upload/v1696042471/webapp/FONDO_zftidw.jpg) !important;
}
.shadow-custom {
  box-shadow: 0px 0px 18px 0.2px rgba(0, 0, 0, 0.15) !important;
  border-radius: 10px;
}
hr {
  border-top: 1px solid var(--primary) !important;
  width: 100% !important;
  color: var(--primary) !important;
}
.card {
  border-bottom: 3px solid var(--secondary);
}
h3,
h2 {
  color: var(--secondary) !important;
}
.referido {
  color: #21201F !important;
  border-radius: 10px;
}
.referido h3 {
  padding: 0px !important;
  border-bottom: 1px solid #21201F;
}
.referido button {
  margin-left: -30px;
  margin-top: -4px;
}
.ref {
  background-color: lightgray !important;
  color: var(--secondary) !important;
  padding: 5px !important;
}
.ref2 {
  background-color: #fff !important;
  color: var(--secondary) !important;
  padding: 5px !important;
}
.card-body {
  border-bottom: 1px solid #cccccc;
}
.container-body {
  color: #cccccc !important;
  border: 1px solid #cccccc;
  border-radius: 10px;
}
.container-body p {
  color: black;
}
.commissions {
  background-color: var(--secondary) !important;
}
.btn-ref {
  color: var(--secondary);
  background: black !important;
  background-image: black !important;
  background-color: black !important;
}
.card-subtitle {
  color: black !important;
}
/*# sourceMappingURL=self-management.component.css.map */
