<div class="content">
    <ol class="breadcrumb bg-transparent ml-3">
      <li class="breadcrumb-item">
        <a routerLink="/funding-program/home"> Area Trading </a>
      </li>
      <li class="breadcrumb-item">Ranking</li>
    </ol>

    <div class="row ml-3">
      <div class="col-12">
        <p>Tabla con los que mas utilidades han generado.</p>
      </div>
    </div>

  </div>
  