<div class="d-none d-sm-block bg-gray">
  <footer class="footer" [ngClass]="{
      'class-user': userDataService?.userData$?.value?.rol === 'student'
    }">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-sm-8 col-md-9 col-lg-6">
          <ul class="nav">
            <li class="nav-item">
              <a href="https://sniperpro.digital" target="_blank" rel="noopener noreferrer">
                <img class="logo"
                  src="https://res.cloudinary.com/ddw7odxzr/image/upload/v1719765038/RC_11326_Logo_Final_Start_Academy_Alex_Logotipo_Positivo_detuei.png"
                  alt="" />
              </a>
            </li>
          </ul>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-3 col-lg-6 d-flex align-items-center justify-content-end">
          <div class="">
            
            <a href="#" target="_blank">
              <i class="fa-brands fa-instagram fa-3x align-middle"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="copyright">
            &copy; {{ test | date : "yyyy" }} Hecho con
            <i class="tim-icons icon-heart-2"> </i> para
            <a href="https://sniperpro.digital/home/" target="_blank"> Start Academy </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>

<div class="d-block d-sm-none">
  <footer class="footer p-3" [ngClass]="{
      'class-user': userDataService?.userData$?.value?.rol === 'student'
    }">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <ul class="nav">
            <li class="nav-item">
              <a href="https://sniperpro.digital" target="_blank" rel="noopener noreferrer">
                <img class="logo"
                  src="https://res.cloudinary.com/ddw7odxzr/image/upload/v1719765038/RC_11326_Logo_Final_Start_Academy_Alex_Logotipo_Positivo_detuei.png"
                  alt="" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="mb-2">
            
            <a href="#" target="_blank">
              <i class="fa-brands fa-instagram-square fa-3x align-middle"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="copyright">
            &copy; {{ test | date : "yyyy" }} Hecho con
            <i class="tim-icons icon-heart-2"> </i> por
            <a href="https://startacademy.digital/" target="_blank"> Start Academy </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>
