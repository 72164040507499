/* src/app/shared/components/footer/footer.component.css */
img {
  width: 100px !important;
  margin: 10px !important;
}
nav-link {
  font-size: 3em !important;
}
.class-user {
  padding: 20px 30px 30px 20px !important;
}
.fa-brands::before {
  border-radius: 20px !important;
  color: var(--primary) !important;
  margin-right: 5px !important;
}
@media screen and (min-width: 600px) and (max-width: 800px) {
  .nones {
    display: none !important;
  }
}
/*# sourceMappingURL=footer.component.css.map */
