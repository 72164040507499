<div class="content">
  <ol class="breadcrumb bg-transparent ml-3">
    <li class="breadcrumb-item text-primary">
      <a routerLink="/my-packages">Area Trading</a>
    </li>
    <li class="breadcrumb-item">Mis Paquetes</li>
  </ol>
  <h3 class="title">Mis Paquetes</h3>
  <div class="row pl-4"> 
    <div class="col-lg-10 col-md-6">
      <div class="card card-stats shadow text-center ">
        <div class="card-body">
          <div class="row container-title ">
            <div class="col-8">
              <h2 class="card-title mt-2 mb-1 text-left">Datos de Facturación | Ordén Recibida  </h2>
            </div>
            <div class="col-4">
              <img src="https://res.cloudinary.com/app-intcapex-com/image/upload/v1680689760/LOGO_LOGIN-30_xmssxz.svg" alt="" width="150px">
            </div>
          </div>
          <div class="row mt-3 mb-3 pb-3 container-info">
            <div class="col-7 ">
              <div class="row">
                <div class="col-5">
                  <p class="text-left" > <strong> Número de órden: </strong> 
                    <span> {{ package.id }} </span>
                  </p> 
                </div> 
                <div class="col-7">
                  <p class="text-left" > <strong> Tamaño de la cuenta: </strong> 
                    <span> {{  '$ ' +  package.balance + ' ' }} {{ package.currencies | uppercase }} </span> 
                  </p> 
                </div>
                <!-- <div class="col-5">
                  <p class="text-left" > <strong> Típo de cuenta: </strong> 
                    <span> {{ package.account_type | uppercase }}</span> 
                  </p> 
                </div>
                <div class="col-7">
                  <p class="text-left" > <strong> Plataforma: </strong> 
                    <span> MetaTrader 5 </span> 
                  </p> 
                </div> -->
                <div class="col-5">
                  <p class="text-left" > <strong> Nombre: </strong> 
                    <span> {{ package.first_name + ' ' + package.last_name  }}</span> 
                  </p> 
                </div>
                <div class="col-7">
                  <!-- <p class="text-left" > <strong> Dirección: </strong> 
                    <span> av 100pre viva 123 </span> 
                  </p>  -->
                </div>
                <div class="col-5">
                  <p class="text-left" > <strong> País:  </strong> 
                    <span> {{ package.country  }}</span> 
                  </p> 
                </div>
                <div class="col-7">
                  <p class="text-left" > <strong> Ciudad:</strong> 
                    <span> {{  package.city }} </span> 
                  </p> 
                </div>
                <div class="col-5">
                  <p class="text-left" > <strong> Código Postal:  </strong> 
                    <span> {{ package.postal_code }} </span> 
                  </p> 
                </div>
                <div class="col-7">
                  <p class="text-left" > <strong> Telefono:</strong> 
                    <span> {{  package.phone_number }} </span> 
                  </p> 
                </div>
              </div>
              
            </div>
    
            <div class="col-5">
              <div class="row bg-label mt-3 mb-2 mr-2  ">
                <div class="col-2 my-auto">
                  <img 
                  src="https://res.cloudinary.com/app-intcapex-com/image/upload/v1682476124/email_b0aiqe.svg" >
                </div>
                <div class="col-10">
                  <div class="row">
                    <p class="text-left" > <strong> Correo: </strong> </p>
                  </div>
                  <div class="row">
                    <span class="text-right" > {{ package.email }}</span>
                  </div>
                </div>
              </div>
              <div class="row bg-label mt-2 mr-2">
                <div class="col-2 my-auto">
                  <img 
                    src="https://res.cloudinary.com/app-intcapex-com/image/upload/v1682476125/valor-challenge_uyqzmc.svg" 
                    alt="">
                </div>
                <div class="col-10">
                  <div class="row">
                    <p class="text-left" > <strong>Valor del Challenge:</strong> </p>
                  </div>
                  <div class="row">
                    <span class="text-right" > {{ '$' + package?.value  + ' USD' }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 text-right mt-3">
              <button 
                class="btn btn-secondary btn-lg"
                [routerLink]="['/funding-program/checkout/', packageId]"
              >
                pagar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




